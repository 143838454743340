import { TPriceQuartile } from "../../../../../data/types/areas";
import { RentQuartileFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const RentQuartileFilterComponent = () => {

  const all = useSearchState(state => state.query.all.rentQuartile);
  const selectedValue = useSearchState(state => state.filters.selectedValues['AVG_RENT_PRICE_QUARTILE_KEY'] as TPriceQuartile);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new RentQuartileFilter(e.target.value as TPriceQuartile));

  return (
    <span>
      <span className='mr-1'>Only rents in the</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>

      <span className='ml-1'>price quartile</span>
    </span>
  );
};