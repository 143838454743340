import { TFilterOption, useSearchState } from '../../state/search.state';
import { SearchBox } from './search/SearchBox';

export const QueryComponent = () => {

  const options = useSearchState(state => state.filters.options);
  const removeFilter = useSearchState(state => state.removeFilter);
  const openModal = useSearchState(state => state.openFiltersModal);
  const onRemoveFilter = (option: TFilterOption) => removeFilter(option.key);

  return (
    <div>
      <div className='m-2'>
        <SearchBox mode='inplace'/>
      </div>
      <div className='columns is-mobile is-vcentered m-0'>
        <div className='column'>
          <span><strong>Filters</strong></span>
        </div>
        <div className='column is-narrow'>
          <button className='button is-primary is-pulled-right' onClick={() => openModal()}>
            <span>
              Add filter
            </span>
            <span className='icon is-small'>
              <i className='fas fa-plus'></i>
            </span>
          </button>
        </div>
      </div>
      <div>
        <hr className='mt-1 mb-2' />
      </div>
      <div>
        {
          options.filter(option => option.isSelectable).map(option => (
            <div key={option.key} className='p-0 m-0 columns is-vcentered is-mobile' >
              <div className='column is-narrow mr-0 pr-0'>
                <a className={option.canDelete ? '' : 'is-disabled'} onClick={() => onRemoveFilter(option)}>
                  <span className='icon is-small'>
                    <i className='fas fa-times-circle'></i>
                  </span>
                </a>
              </div>
              <div className='column'>
                <option.component />
              </div>
            </div>
          ))
        }
      </div>
      <div>
        <hr />
      </div>
    </div>
  )
}