import { TCouncilTax } from "../../../../../data/types/areas";
import { CouncilTaxFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const CouncilTaxFilterComponent = () => {

  const all = useSearchState(state => state.query.all.councilTax);
  const selectedValue = useSearchState(state => state.filters.selectedValues['COUNCIL_TAX_KEY'] as TCouncilTax);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new CouncilTaxFilter(Number(e.target.value as TCouncilTax)));

  return (
    <span>
      <span className='mr-1'>At most an average Council Tax of:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>£{item}</option>))}
      </select>
    </span>
  );
};