import { LatLngTuple } from 'leaflet';

export type TLocation = 
  | 'london' 
  | 'san-francisco' 
  | 'barcelona'
  | 'berlin'
  | 'bucharest'
  | 'budapest'
  | 'madrid'
  | 'new-york'
  | 'paris'
  | 'prague'
  | 'rome'
  | 'vienna' ; 

export const LocationGPSCoords: Map<TLocation, LatLngTuple> = new Map([
  ['london', [51.509865, -0.118092]],
]);

export const LocationBounds: Map<TLocation, any> = new Map([
  ['london', [[51.2867601558474, -0.510296147662242], [51.6917727148959, 0.333995716243448]]],
]);

export const LocationToAreaNames: Map<TLocation, string> = new Map([
  ['london', 'borough'],
]);
export const LocationToSubAreaNames: Map<TLocation, string> = new Map([
  ['london', 'ward'],
]);