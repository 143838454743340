import { TLocation } from '../types/location';
import { TLocationInfoValue, TParkInfoValue, TSchoolInfoValue, TTransportInfoValue } from '../types/quant';
import * as fflate from 'fflate';
import { Buffer } from 'buffer';

export const loadInfo = async (location: TLocation): Promise<Array<TLocationInfoValue>> => {
  const path = '/assets/data/london.info.json';
  const response = await fetch(path);
  return await unpackGzippedData(response) as Array<TLocationInfoValue>;
}

export const loadTransportInfo = async (location: TLocation): Promise<TTransportInfoValue> => {
  const path = '/assets/data/london.transport.lines.json';
  const response = await fetch(path);
  return await unpackGzippedData(response) as TTransportInfoValue;
};

export const loadParkInfo = async (location: TLocation): Promise<Array<TParkInfoValue>> => {
  const path = '/assets/data/london.parks.json';
  const response = await fetch(path);
  return await unpackGzippedData(response) as Array<TParkInfoValue>;
};

export const loadSchoolsInfo = async (location: TLocation): Promise<Array<TSchoolInfoValue>> => {
  const path = '/assets/data/london.schools.json';
  const response = await fetch(path);
  return await unpackGzippedData(response) as Array<TSchoolInfoValue>;
};

const unpackGzippedData = async  <T> (response: Response): Promise<T> => {
  const buffer = await response.arrayBuffer();
  const uint8Array = new Uint8Array(buffer);
  const unzipped = fflate.unzlibSync(uint8Array);
  const jsonString = Buffer.from(unzipped).toString();
  return JSON.parse(jsonString) as T;
};