import { TTransportQuality } from "../../../../../data/types/areas"
import { TransportQualityFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const TransportQualityFilterComponent = () => {

  const all = useSearchState(state => state.query.all.transportQuality);
  const selectedValue = useSearchState(state => state.filters.selectedValues['TRANSPORT_QUALITY_KEY'] as TTransportQuality);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new TransportQualityFilter(e.target.value as TTransportQuality));

  return (
    <span>
      <span className='mr-1'>Only locations with</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>

      <span className='ml-1'>transport link quality</span>
    </span>
  )
}