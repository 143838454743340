import { TSelectedValuesMap } from '../../pages/search/state/search.state';
import { TBedroomNumber, TPostCodePrefix, TPropertyFeature, TPropertyScheme, TPropertyType } from '../types/areas';
import { TTransactionType } from '../types/areas';

const PARTNER_DOMAIN = 'https://www.zoopla.co.uk';

type TOperationType = 'for-sale' | 'to-rent' | 'new-homes';

const getTransactionType = (selectedValues: TSelectedValuesMap): TTransactionType => {
  return selectedValues['TRANSACTION_TYPE_KEY'] ?? 'buy' as TTransactionType;
}

const getOperation = (selectedValues: TSelectedValuesMap): TOperationType => {
  const scheme = selectedValues['PROPERTY_SCHEME_KEY'] as TPropertyScheme;
  if (scheme && scheme === 'new-build') {
    return 'new-homes';
  }

  const transaction = getTransactionType(selectedValues);
  return transaction === 'buy' ? 'for-sale' : 'to-rent';
}

const appendPropertyScheme = (link: string, selectedValues: TSelectedValuesMap): string => {
  const scheme = selectedValues['PROPERTY_SCHEME_KEY'] as TPropertyScheme;

  if (!scheme) {
    return link;
  }

  let newLink = link;
  
  if (scheme === 'retirement') {
    newLink += '&is_auction=false&is_shared_ownership=false&new_homes=exclude&is_retirement_home=true';
  }
  else if (scheme === 'shared-ownership') {
    newLink += '&is_auction=false&is_shared_ownership=true&new_homes=exclude&is_retirement_home=false';
  }
  else if (scheme === 'auction') {
    newLink += '&is_auction=true&is_shared_ownership=false&new_homes=exclude&is_retirement_home=false';
  }

  return newLink;
};

const appendChainFree = (link: string, selectedValues: TSelectedValuesMap): string => {
  let newLink = link;
  const chainFree = selectedValues['CHAIN_FREE_KEY'] as boolean ?? false;

  if (chainFree) {
    newLink += '&chain_free=true';
  }

  return newLink;
};

const appendMustHaves = (link: string, selectedValues: TSelectedValuesMap): string => {
  let newLink = link;
  const mustHaves = selectedValues['PROPERTY_FEATURE_KEY'] as Array<TPropertyFeature>;
  if (mustHaves && mustHaves.length > 0) {
    for (const mustHave of mustHaves) {
      if (mustHave === 'balcony') {
        newLink += '&feature=has_balcony_terrace';
      }
      if (mustHave === 'garage') {
        newLink += '&feature=has_parking_garage';
      }
      if (mustHave === 'garden') {
        newLink += '&feature=has_garden';
      }
    }
  }

  return newLink;
};

const appendPropertyType = (link: string, selectedValues: TSelectedValuesMap): string => {
  let newLink = link;

  const propertyType = selectedValues['PROPERTY_TYPE_KEY'] as TPropertyType;
  if (propertyType) {
    newLink += '&property_sub_type=';
    if (propertyType === 'flat') {
      newLink += 'flats';
    }
    else if (propertyType === 'semi-detached') {
      newLink += 'semi_detached';
    }
    else if (propertyType === 'detached') {
      newLink += 'semi_detached';
    }
    else if (propertyType === 'bungalow') {
      newLink += 'bungalow'
    }
    else if (propertyType === 'terraced') {
      newLink += 'terraced';
    }
  }

  return newLink;
};

const appendMinBeds = (link: string, selectedValues: TSelectedValuesMap): string => {
  let newLink = link;

  const minBeds = selectedValues['MIN_BEDROOMS_KEY'];
  if (minBeds) {
    newLink += `&beds_min=${bedsToNumber(minBeds)}`;
  }

  return newLink;
}

const appendMaxBeds = (link: string, selectedValues: TSelectedValuesMap): string => {
  let newLink = link;

  const maxBeds = selectedValues['MAX_BEDROOMS_KEY']
  if (maxBeds) {
    newLink += `&beds_max=${bedsToNumber(maxBeds)}`;
  }

  return newLink;
};

const appendRentOrBuyPrices = (link: string, selectedValues: TSelectedValuesMap): string => {
  let newLink = link;

  let transactionType: TTransactionType = getTransactionType(selectedValues);
  if (transactionType === 'buy') {
    const minPrice = selectedValues['MIN_HOUSE_PRICE_KEY'];
    if (minPrice) {
      newLink += `&price_min=${minPrice}`;
    }
  
    const maxPrice = selectedValues['MAX_HOUSE_PRICE_KEY'];
    if (maxPrice) {
      newLink += `&price_max=${maxPrice}`;
    }
  } else {
    const minRent = selectedValues['MIN_RENT_PRICE_KEY'];
    if (minRent) {
      newLink += `&price_min=${minRent}`;
    }

    const maxRent = selectedValues['MAX_RENT_PRICE_KEY'];
    if (maxRent) {
      newLink += `&price_max=${maxRent}`;
    }
    newLink += `&price_frequency=per_month`;
  }

  return newLink;
}

const appendSource = (link: string): string => {
  let newLink = link;
  newLink += '&search_source=refine';
  return newLink;
};

const buildPropertyUrlPart = (selectedValues: TSelectedValuesMap) => {
  const propertyType = selectedValues['PROPERTY_TYPE_KEY'] as TPropertyType;
  if (propertyType) {
    switch (propertyType) {
      case 'flat': return 'flats';
      case 'semi-detached': return 'houses';
      case 'detached': return 'houses';
      case 'terraced': return 'houses';
      case 'bungalow': return 'bungalows';
    }
  }

  return 'property';
}

export const formResultLink = (postCode: TPostCodePrefix, selectedValues: TSelectedValuesMap): string => {

  const operation = getOperation(selectedValues);
  const property = buildPropertyUrlPart(selectedValues);
  
  let link = `${PARTNER_DOMAIN}/${operation}/${property}/${postCode}/?q=${postCode}`;

  link = appendRentOrBuyPrices(link, selectedValues);
  link = appendMaxBeds(link, selectedValues);
  link = appendMinBeds(link, selectedValues);
  link = appendMustHaves(link, selectedValues);
  link = appendChainFree(link, selectedValues);
  link = appendPropertyScheme(link, selectedValues);
  link = appendSource(link);
  link = appendPropertyType(link, selectedValues);
  
  return link;
};

const bedsToNumber = (data: TBedroomNumber): number => {
  if (data === 'Studio') return 0;
  else if (data === '10+') return 10;
  else return parseInt(data);
}