import chroma from 'chroma-js';
import orderBy from 'lodash.orderby';
import { TAreaName, TBedroomNumber, TBranchName, TColor, TLineName, TParkName, TPostCodePrefix, TPropertyScheme, TPropertyType, TSchoolGrade, TSchoolName, TStationName, TSubAreaCode, TSubAreaName, TTransactionType, TTransportQuality } from '../../../data/types/areas';
import { TSubAreaExploreGeoData } from '../../../data/types/geo';
import { TLocationInfoValue } from '../../../data/types/quant';
import { uniqueArray } from '../../../utils/array';
import { generateNumbers, generateUUID } from '../../../utils/math';
import { EMPTY_SEARCH_TERM } from './defaults';
import { IFilter, NorthVsSouthFilter, CardinalPointFilter, TransactionTypeFilter, EmptyFilter, InsideCCFilter, InsideLEZFilter, InsideULEZFilter, InnerVsOuterFilter, NextToRiverFilter, PropertyTypeFilter, PriceQuartileFilter, RentQuartileFilter, MinBedroomsFilter, MaxBedroomsFilter, MinPriceFilter, MaxPriceFilter, MinRentFilter, MaxRentFilter, AreaNameFilter, SubAreaNameFilter, PostCodeFilter, UndergroundLineFilter, UndergroundStationFilter, YearlyPriceIncreaseFilter, DecadePriceIncreaseFilter, PropertyFeatureFilter, PropertySchemeFilter, ChainFreeFilter, UndergroundBranchFilter, TransportQualityFilter, CouncilTaxFilter, ParkNameFilter, GreenCoverFilter, SchoolGradeFilter, SchoolDensityFilter, SchoolNameFilter, FinalPassFilter } from './filters';
import { TDataState, TFilterKey, TFilterMap, TFilterOption, TGeoFeaturesState, TQueryValues, TResults2State, TSortCondition, TSortOption } from './search.state';
import * as turf from 'turf';

///
/// SORTING

/**
 * 
 * @note: MAIN FUNCTION
 * @param data the original data for a location
 * @param options the sorting options
 * @param transactionType the type of transaction - 'buy' or 'rent'
 * @returns a filtered and sorted list of location informations
 */
export const sortData = (
  data: Array<TLocationInfoValue>,
  options: Array<TSortOption>,
  transactionType: TTransactionType,
): Array<TLocationInfoValue> => {
  const conditions = getSortConditions(options, transactionType);
  const sorted = orderBy(data, conditions.comparators, conditions.orders);
  return sorted;
};

const getSortConditions = (options: Array<TSortOption>, type: TTransactionType): TSortCondition => {
  const comparators: Array<string> = options.map(o => o.comparativeValue(type));
  const orders: Array<'asc' | 'desc'> = options.map(o => o.value === 'highest' ? 'desc' : 'asc');

  const priceSort = options.find(o => o.key === 'PRICE_SORT_KEY');

  if (priceSort) {
    const finalComparator = type === 'buy' ? 'avgPrice' : 'avgRent';
    const finalOrder: 'asc' | 'desc' = priceSort.value === 'highest' ? 'desc' : 'asc';
    comparators.push(finalComparator);
    orders.push(finalOrder);
  }

  return { comparators, orders };
};

export const getAvailableSortingOptions = (
  existing: Array<TSortOption>,
  all: Array<TSortOption>,
): Array<TSortOption> => all.filter(e => !existing.map(t => t.key).includes(e.key));

///
/// CREATE RESULTS

/**
 * @note: MAIN FUNCTION
 * @note: this method takes in input from the method below: @filterAllDataAndGetResultAndAvailableValues
 * @param filtered a list of all location info values, sorted and coloured
 * @param subCodes a lit of all of the sub codes of the afore-mentioned location info values
 * @returns the results state
 */
export const createResults2 = (
  filtered: Array<TLocationInfoValue>,
  subCodes: Array<TSubAreaCode>
): TResults2State => {

  // do direct results
  const directResults = filtered.filter(e => e.isDirectlySelected);
  const viableAlternatives = filtered.filter(e => e.isViableAlternative);
  const displayedResults = directResults.length > 0 ? directResults : (viableAlternatives.length > 0 ? viableAlternatives : filtered);

  const selectedResult = displayedResults[0];
  const hasDirectResults = directResults.length > 0;
  const startIndex = 0;
  const endIndex = displayedResults.length - 1;
  const currentIndex = 0;
  const backButtonEnabled = false;
  const nextButtonEnabled = currentIndex < endIndex;

  const ranks = filtered.map(e => e.rank);
  const minRank = Math.min(...ranks);
  const maxRank = Math.max(...ranks);

  // do geo
  const origSubArea = subCodeToSubAreaGeoData(filtered, subCodes);
  const currentFeatures = origSubArea.features;
  const newFeatures = currentFeatures.map(f => ({ ...f, hovering: f.properties.sub_code === selectedResult.sub_code }));
  const subArea: TGeoFeaturesState = { ...origSubArea, uuid: generateUUID(), features: newFeatures };
  const geo = { subArea };

  return {
    geo,
    hasDirectResults,
    directResults,
    viableAlternatives,
    displayedResults,
    selectedResult,
    startIndex,
    endIndex,
    currentIndex,
    backButtonEnabled,
    nextButtonEnabled,
    minRank,
    maxRank,
  };
}

///
/// FILTERING

/**
 * @note: MAIN FUNCTION
 * @param data all the data information in the system
 * @param filters a map of filters
 * @returns all the area sub-codes that result from the filtering + all the available values for each filter
 */
export const filterAllDataAndGetResultAndAvailableValues = (
  data: TDataState,
  filters: TFilterMap,
): {
  subCodes: Array<TSubAreaCode>,
  filtered: Array<TLocationInfoValue>,
} => {

  let result = data.quant
    .map(e => ({ ...e, rank: 0 }))
    .map(e => {
      let item = e;
      for (const key of Object.keys(filters)) {
        const filter = filters[key];
        item = filter.rank(item, data);
      }
      return item;
    });

  result = pricessWithFinalPassFilter(result);
  result = assignColors(result);
  result = result.sort((a, b) => {
    if (a.isDirectlySelected && !b.isDirectlySelected) {
      return -1;
    }
    else if (!a.isDirectlySelected && b.isDirectlySelected) {
      return +1;
    }
    else if (a.isDirectlySelected && b.isDirectlySelected) {
      return b.rank - a.rank;
    }
    else {
      return b.rank - a.rank;
    }
  });

  const totalResult = result.map(e => e.sub_code);

  return { subCodes: totalResult, filtered: result };
}

const pricessWithFinalPassFilter = (data: Array<TLocationInfoValue>): Array<TLocationInfoValue> => {
  const directlySelectedItems = data.filter(e => e.isDirectlySelected);
  const directlySelectedItemsAsGeo = subCodeToSubAreaGeoData(directlySelectedItems);
  const directlySelectedItemsCenter = turf.centroid(directlySelectedItemsAsGeo)['geometry']['coordinates'];
  const lastFilter = new FinalPassFilter(directlySelectedItemsCenter);
  return data.map(e => lastFilter.rank(e));
}

const assignColors = (data: Array<TLocationInfoValue>): Array<TLocationInfoValue> => {
  const YELLOW_TO_GREEN_3_COLORS = ['#fdae61', '#ffffbf', '#a6d96a'];
  const RED_TO_GREEN_5_COLORS: Array<TColor> = ['#fd8861', '#fdae61', '#ffffbf', '#a6d96a', '#a6d96a'];
  const ranks = uniqueArray(data.filter(e => !e.isDirectlySelected).map(e => e.rank));
  const min = Math.min(...ranks);
  const max = Math.max(...ranks);

  let step = (max - min) / 5;
  if (step === 0) {
    step = 0.5;
  }

  let colorGradient = RED_TO_GREEN_5_COLORS;
  if (ranks.length < 3) {
    colorGradient = YELLOW_TO_GREEN_3_COLORS;
  }

  const limits = generateNumbers(min, max, step);
  const colors = chroma.scale(colorGradient).domain(limits).colors(limits.length);
  return data.map(e => ({ ...e, rankColor: assignColor(e.rank, limits, colors), isViableAlternative: assignViableAlternatives(e, limits) }));
}

const assignColor = (value: number, limits: Array<number>, colors: Array<TColor>): TColor => {
  for (let i = 0; i < limits.length; i++) {
    const lowerBound = limits[i];
    const upperBound = limits[i + 1];
    if (value >= lowerBound && value < upperBound) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

const assignViableAlternatives = (e: TLocationInfoValue, limits: Array<number>): boolean => {
  if (e.isDirectlySelected) {
    return false;
  }

  const rank = e.rank;
  const prevLimit = limits[limits.length - 2];
  const lastLimit = limits[limits.length - 1];
  return prevLimit <= rank && rank <= lastLimit;
}

/**
 * 
 * @param option a filtering options
 * @param value a starting value for the filter
 * @returns a valid filter class
 */
export const createFilterFromOption = (option: TFilterOption, value: any): IFilter => {
  switch (option.key) {
    case 'NORTH_VS_SOUTH_KEY': return new NorthVsSouthFilter(value);
    case 'CARDINAL_POINT_KEY': return new CardinalPointFilter(value);
    case 'TRANSACTION_TYPE_KEY': return new TransactionTypeFilter(value);
    case 'INSIDE_CC_ZONE_KEY': return new InsideCCFilter(value);
    case 'INSIDE_LEZ_ZONE_KEY': return new InsideLEZFilter(value);
    case 'INSIDE_ULEZ_ZONE_KEY': return new InsideULEZFilter(value);
    case 'INNER_VS_OUTER_KEY': return new InnerVsOuterFilter(value);
    case 'NEXT_TO_RIVER_KEY': return new NextToRiverFilter(value);
    case 'PROPERTY_TYPE_KEY': return new PropertyTypeFilter(value);
    case 'AVG_HOUSE_PRICE_QUARTILE_KEY': return new PriceQuartileFilter(value);
    case 'AVG_RENT_PRICE_QUARTILE_KEY': return new RentQuartileFilter(value);
    case 'MIN_BEDROOMS_KEY': return new MinBedroomsFilter(value);
    case 'MAX_BEDROOMS_KEY': return new MaxBedroomsFilter(value);
    case 'MIN_HOUSE_PRICE_KEY': return new MinPriceFilter(value);
    case 'MAX_HOUSE_PRICE_KEY': return new MaxPriceFilter(value);
    case 'MIN_RENT_PRICE_KEY': return new MinRentFilter(value);
    case 'MAX_RENT_PRICE_KEY': return new MaxRentFilter(value);
    case 'AREA_NAME_KEY': return new AreaNameFilter(value);
    case 'SUB_AREA_NAME_KEY': return new SubAreaNameFilter(value);
    case 'POST_CODE_KEY': return new PostCodeFilter(value);
    case 'UNDERGROUND_LINE_KEY': return new UndergroundLineFilter(value);
    case 'UNDERGROUND_BRANCH_KEY': return new UndergroundBranchFilter(value);
    case 'UNDERGROUND_STATION_KEY': return new UndergroundStationFilter(value);
    case 'HOUSE_PRICE_AVG_YEARLY_INCREASE': return new YearlyPriceIncreaseFilter(value);
    case 'HOUSE_PRICE_DECADE_INCREASE': return new DecadePriceIncreaseFilter(value);
    case 'PROPERTY_FEATURE_KEY': return new PropertyFeatureFilter(value);
    case 'PROPERTY_SCHEME_KEY': return new PropertySchemeFilter(value);
    case 'CHAIN_FREE_KEY': return new ChainFreeFilter(value);
    case 'TRANSPORT_QUALITY_KEY': return new TransportQualityFilter(value);
    case 'COUNCIL_TAX_KEY': return new CouncilTaxFilter(value);
    case 'PARK_NAME_KEY': return new ParkNameFilter(value);
    case 'GREEN_COVER_KEY': return new GreenCoverFilter(value);
    case 'AVG_SCHOOL_GRADE_KEY': return new SchoolGradeFilter(value);
    case 'NUMBER_OF_SCHOOLS_KEY': return new SchoolDensityFilter(value);
    case 'SCHOOL_NAME_KEY': return new SchoolNameFilter(value);
    default: return new EmptyFilter();
  }
};

/**
 * 
 * @param query the full object of existing queriable data
 * @param key a filtering key
 * @returns a valid item of data to instantiate a new filter, for example
 */
export const getFirstValidQueryItem = (
  query: TQueryValues,
  key: TFilterKey,
): any | undefined => {
  switch (key) {
    case 'NORTH_VS_SOUTH_KEY':
      return query.northOrSouth[0];
    case 'CARDINAL_POINT_KEY':
      return query.cardinalPoints[0];
    case 'TRANSACTION_TYPE_KEY':
      return 'buy' as TTransactionType;
    case 'INSIDE_CC_ZONE_KEY':
      return query.insideCC[0];
    case 'INSIDE_LEZ_ZONE_KEY':
      return query.insideLEZ[0];
    case 'INSIDE_ULEZ_ZONE_KEY':
      return query.insideULEZ[0];
    case 'INNER_VS_OUTER_KEY':
      return query.innerOrOuter[0];
    case 'NEXT_TO_RIVER_KEY':
      return true;
    case 'PROPERTY_TYPE_KEY':
      return 'flat' as TPropertyType;
    case 'AVG_HOUSE_PRICE_QUARTILE_KEY':
      return query.priceQuartile[0];
    case 'AVG_RENT_PRICE_QUARTILE_KEY':
      return query.rentQuartile[0];
    case 'MIN_BEDROOMS_KEY':
      return 'Studio' as TBedroomNumber;
    case 'MAX_BEDROOMS_KEY':
      return '10+' as TBedroomNumber;
    case 'MIN_HOUSE_PRICE_KEY':
      return query.minHousePrice[0];
    case 'MAX_HOUSE_PRICE_KEY':
      return query.maxHousePrice[query.maxHousePrice.length - 1];
    case 'MIN_RENT_PRICE_KEY':
      return query.minRentPrice[0];
    case 'MAX_RENT_PRICE_KEY':
      return query.maxRentPrice[query.maxRentPrice.length - 1];
    case 'AREA_NAME_KEY':
      return EMPTY_SEARCH_TERM as TAreaName;
    case 'SUB_AREA_NAME_KEY':
      return EMPTY_SEARCH_TERM as TSubAreaName;
    case 'POST_CODE_KEY':
      return EMPTY_SEARCH_TERM as TPostCodePrefix;
    case 'UNDERGROUND_LINE_KEY':
      return EMPTY_SEARCH_TERM as TLineName;
    case 'UNDERGROUND_BRANCH_KEY':
      return EMPTY_SEARCH_TERM as TBranchName;
    case 'UNDERGROUND_STATION_KEY':
      return EMPTY_SEARCH_TERM as TStationName;
    case 'HOUSE_PRICE_AVG_YEARLY_INCREASE':
      return query.yearlyHousePriceIncrease[0];
    case 'HOUSE_PRICE_DECADE_INCREASE':
      return query.decadeHousePriceIncrease[0];
    case 'PROPERTY_FEATURE_KEY':
      return [];
    case 'PROPERTY_SCHEME_KEY':
      return 'existing' as TPropertyScheme;
    case 'CHAIN_FREE_KEY':
      return true;
    case 'TRANSPORT_QUALITY_KEY':
      return 'high' as TTransportQuality;
    case 'COUNCIL_TAX_KEY':
      return query.councilTax[0];
    case 'PARK_NAME_KEY':
      return EMPTY_SEARCH_TERM as TParkName;
    case 'GREEN_COVER_KEY':
      return query.greenCover[0];
    case 'AVG_SCHOOL_GRADE_KEY':
      return query.schoolGrade[0];
    case 'NUMBER_OF_SCHOOLS_KEY':
      return query.schoolDensity[0];
    case 'SCHOOL_NAME_KEY':
      return EMPTY_SEARCH_TERM as TSchoolName;
    default:
      return undefined;
  }
};

export const getAvailableFilteringOptions = (
  existing: Array<TFilterOption>,
  all: Array<TFilterOption>,
): Array<TFilterOption> => all.filter(e => !existing.map(t => t.key).includes(e.key));

///
/// GEO TRANSFORMS

export const subCodeToSubAreaGeoData = (data: Array<TLocationInfoValue>, sub_codes?: Array<TSubAreaCode>): TGeoFeaturesState => {
  return {
    uuid: generateUUID(),
    type: 'FeatureCollection',
    features: data.map(e => ({
      type: 'Feature',
      rankColor: e.rankColor,
      isDirectlySelected: e.isDirectlySelected,
      selected: sub_codes?.includes(e.sub_code) ?? true,
      properties: {
        code: e.code,
        name: e.sub_name,
        sub_code: e.sub_code
      },
      geometry: {
        type: e.type,
        coordinates: e.coords,
      },
    })),
  };
};

export const exploreToSubAreaGeoData = (data: Array<TLocationInfoValue>): TSubAreaExploreGeoData => {
  return {
    uuid: generateUUID(),
    type: 'FeatureCollection',
    features: data.map(e => ({
      type: 'Feature',
      data: { colors: e.colors, values: e.values },
      properties: {
        code: e.sub_code,
        area_hectares: 0,
        name: e.sub_name,
        sub_code: e.sub_code,
      },
      geometry: {
        type: e.type,
        coordinates: e.coords,
      },
    })),
  };
}

export const schoolGradeNumberToQualifier = (number: number): string => {
  switch (number) {
    case 1: return 'Inadequate';
    case 2: return 'Requires Improvement';
    case 3: return 'Good';
    case 4: return 'Outstanding';
    default: return 'No data';
  }
};

export const formNormalisedRanking = (rank: number, minRank: number, maxRank: number): number => {
  if (rank === 0 && minRank === 0 && maxRank === 0) {
    return 5;
  }

  const NEW_MIN_RANK = 1;
  const NEW_MAX_RANK = 5;

  return (rank - minRank) / (maxRank - minRank) * (NEW_MAX_RANK - NEW_MIN_RANK) + NEW_MIN_RANK;
}