import { TGreenCover } from "../../../../../data/types/areas";
import { GreenCoverFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const GreenCoverFilterComponent = () => {

  const all = useSearchState(state => state.query.all.greenCover);
  const selectedValue = useSearchState(state => state.filters.selectedValues['GREEN_COVER_KEY'] as TGreenCover);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new GreenCoverFilter(Number(e.target.value as TGreenCover)));

  return (
    <span>
      <span className='mr-1'>At least</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}%</option>))}
      </select>

      <span className='ml-1'>covered by trees, parks and gardens</span>
    </span>
  );
};