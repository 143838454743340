import { TTransactionType } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { ordinalOfNumber } from "../../../../../../utils/display";
import { TSelectedValuesMap, useSearchState } from "../../../../state/search.state";
import { StatsStructure } from "../common/StatsStructure";

export const ExpensiveStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const total = useSearchState(state => state.data.quant.length);
  const midPoint = total / 2;

  const transasction = props.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

  const Item = () => {  
    const expensiveIndex = transasction === 'buy' ? props.selected.avgPriceExpensiveIndex : props.selected.avgRentExpensiveIndex;
    const cheapIndex = transasction === 'buy' ? props.selected.avgPriceCheapIndex : props.selected.avgRentCheapIndex;
    const extraCopy = transasction === 'buy' ? 'to buy' : 'to rent';

    let copy = '';
    if (expensiveIndex === 0) {
      copy = 'Most Expensive';
    }
    else if (expensiveIndex < midPoint) {
      const ordinal = ordinalOfNumber(expensiveIndex);
      copy = `${ordinal} Most Expensive`;
    }
    else if (cheapIndex === total) {
      copy = 'Least Expensive'
    }
    else {
      const ordinal = ordinalOfNumber(cheapIndex);
      copy = `${ordinal} Least Expensive`;
    }

    return (<span>{copy} {extraCopy}</span>);
  }

  const Delta = () => null;

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}