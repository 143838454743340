import { TPropertyScheme } from "../../../../../data/types/areas";
import { PropertySchemeFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const PropertySchemeFilterComponent = () => {

  const all = useSearchState(state => state.query.all.propertyScheme);
  const selectedValue = useSearchState(state => state.filters.selectedValues['PROPERTY_SCHEME_KEY'] as TPropertyScheme);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new PropertySchemeFilter(e.target.value as TPropertyScheme));

  const schemeToName = (scheme: TPropertyScheme): string => {
    switch (scheme) {
      case 'auction': return 'Auction';
      case 'new-build': return 'New Build';
      case 'retirement': return 'Retirement Home';
      case 'shared-ownership': return 'Shared Ownership';
      case 'existing': return 'Existing build';
    }
  }
  
  return (
    <span>
      <span className='mr-1'>Property scheme</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{schemeToName(item)}</option>))}
      </select>
    </span>
  );
}