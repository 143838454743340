import { normaliseWithoutSpacesToLowercase } from '../../../../../utils/string';
import { schoolGradeNumberToQualifier } from '../../../state/business-logic';
import { useSearchState } from '../../../state/search.state';

export const ResultInfoComponent = () => {

  const selected = useSearchState(state => state.results2.selectedResult);

  if (!selected) {
    return null;
  }

  const LineDisplay = () => {
    if (selected.lines.length === 0) {
      return null;
    }

    return (
      <>
        <h6>
          <small>
            <span className='icons is-small mr-2'>
              <i className="fas fa-info-circle"></i>
            </span>
            Tube lines
          </small>
        </h6>
        <ul>
          {selected.lines.map(line => (<li key={line}><small>{line}</small></li>))}
        </ul>
      </>
    )
  };

  const StationDisplay = () => {
    if (selected.stations.length === 0) {
      return null;
    }

    return (
      <>
        <h6>
          <small>
            <span className='icons is-small mr-2'>
              <i className="fas fa-info-circle"></i>
            </span>
            Tube stations
          </small>
        </h6>
        <ul>
          {selected.stations.map(station => (<li key={station}><small>{station}</small></li>))}
        </ul>
      </>
    )
  };

  const ParksDisplay = () => {
    if (selected.parks.length === 0) {
      return null;
    }

    return (
      <>
        <h6>
          <small>
            <span className='icons is-small mr-2'>
              <i className="fas fa-info-circle"></i>
            </span>
            Parks
          </small>
        </h6>
        <ul>
          {selected.parks.map(park => (<li key={park}><small>{park}</small></li>))}
        </ul>
      </>
    )
  };

  const SchoolsDisplay = () => {
    const schools = useSearchState(state => state.data.schools);

    if (selected.schools.length === 0) {
      return null;
    }

    const schoolNames = selected.schools.map(e => normaliseWithoutSpacesToLowercase(e));
    const desiredSchools = schools.filter(e => schoolNames.includes(normaliseWithoutSpacesToLowercase(e.name)));

    return (
      <>
        <h6>
          <small>
            <span className='icons is-small mr-2'>
              <i className="fas fa-info-circle"></i>
            </span>
            Schools
          </small>
        </h6>
        <ul>
          {desiredSchools.map(s => (<li key={s.id}><small>{s.name} ({schoolGradeNumberToQualifier(s.grade)})</small></li>))}
        </ul>
      </>
    )
  }

  return (
    <div className='content'>
      <LineDisplay />
      <StationDisplay />
      <ParksDisplay />
      <SchoolsDisplay />
    </div>
  );
};