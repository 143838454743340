import { TGreenCover } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const GreenCoverStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {
    return (<span>Is {props.selected.greenCoverBucket}% covered by trees, parks and gardens</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['GREEN_COVER_KEY'] as TGreenCover;

    if (!filter) {
      return null;
    }

    const value = props.selected.greenCoverBucket - filter;
    const text = `${value}%`;

    return (<StatsArrow text={text} value={value} />);
  }

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}