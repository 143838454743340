import { TPriceQuartile, TTransactionType } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { capitalize } from "../../../../../../utils/string";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const QuartileStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const transasction = props.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

  const Item = () => {  
    const copy = transasction === 'buy' ? 'Price' : 'Rent';
    const value = transasction === 'buy' ? props.selected.avgPriceQuartile : props.selected.avgRentQuartile;

    return (<span>{capitalize(value)} {copy} Quartile</span>);
  }

  const Delta = () => {
    const filterKey = transasction === 'buy' ? 'AVG_HOUSE_PRICE_QUARTILE_KEY' : 'AVG_RENT_PRICE_QUARTILE_KEY';
    const filter = props.selectedValues[filterKey] as TPriceQuartile;

    if (!filter) {
      return null;
    }

    const value = filter === props.selected.avgPriceQuartile ? 1 : -1;
    const text = value > 0 ? 'matching' : 'not matching';
    return (<StatsArrow text={text} value={value} />);
  };

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}