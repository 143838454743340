import { THousePrice, TTransactionType } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { formatNumberInPercetange } from "../../../../../../utils/display";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const YoYIncreaseStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const transasction = props.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

  if (transasction === 'rent') {
    return null;
  }

  const Item = () => {
    const value = props.selected.avgYearlyPriceIncreaseBucket

    return (<span>{formatNumberInPercetange(value)} Year-on-Year price increase</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['HOUSE_PRICE_AVG_YEARLY_INCREASE'] as THousePrice;

    if (!filter) {
      return null;
    }

    const value = props.selected.avgYearlyPriceIncreaseBucket - filter;
    const text = `${value}%`;

    return (<StatsArrow text={text} value={value} />);
  }

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}