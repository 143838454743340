import { TPropertyType } from "../../../../../data/types/areas";
import { PropertyTypeFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const PropertyTypeFilterComponent = () => {

  const all = useSearchState(state => state.query.all.propertyType);
  const selectedValue = useSearchState(state => state.filters.selectedValues['PROPERTY_TYPE_KEY'] as TPropertyType);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new PropertyTypeFilter(e.target.value as TPropertyType));

  return (
    <span>
      <span className='mr-1'>Property type</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>
    </span>
  );
};