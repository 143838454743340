import { TBedroomNumber } from "../../../../../data/types/areas";
import { MinBedroomsFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const MinBedroomsFilterComponent = () => {

  const all = useSearchState(state => state.query.all.bedrooms);
  const selectedValue = useSearchState(state => state.filters.selectedValues['MIN_BEDROOMS_KEY'] as TBedroomNumber);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new MinBedroomsFilter(e.target.value as TBedroomNumber));

  return (
    <span>
      <span className='mr-1'>Min Bedrooms:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>
    </span>
  );
};