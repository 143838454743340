export const StatsArrow = (props: { value: number, text: string, inverted: boolean }) => {
  let colorClass = '';
  if (!props.inverted) {
    colorClass = props.value === 0 ? 'has-text-info' : (props.value > 0 ? 'has-text-success' : 'has-text-danger');
  } else {
    colorClass = props.value === 0 ? 'has-text-info' : (props.value > 0 ? 'has-text-danger' : 'has-text-success');
  }
  const arrow = props.value === 0 ? <>●</> : (props.value > 0 ? <>▲</> : <>▼</>);

  return (
    <>
      (
      <span className={'icons mr-1 is-size-7 ' + colorClass}>
        {arrow}
      </span>
      <span className='is-size-7'>
        <strong>{props.text}</strong>
      </span>
      )
    </>
  );
};

StatsArrow.defaultProps = {
  inverted: false,
};