import { TSchoolGrade } from '../../../../../data/types/areas';
import { SchoolGradeFilter } from '../../../state/filters';
import { useSearchState } from '../../../state/search.state';

export const SchoolGradeFilterComponent = () => {

  const all = useSearchState(state => state.query.all.schoolGrade);
  const selectedValue = useSearchState(state => state.filters.selectedValues['AVG_SCHOOL_GRADE_KEY'] as TSchoolGrade);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new SchoolGradeFilter(e.target.value as TSchoolGrade));

  return (
    <span>
      <span className='mr-1'>Avg School rating greater than:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>

      <small>
        <div>
          Where:
          <ul>
            <li>1 = Inadequate</li>
            <li>2 = Requires Improvement</li>
            <li>3 = Good</li>
            <li>4 = Outstanding</li>
          </ul>
        </div>
      </small>
    </span>
  );
};