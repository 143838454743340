import { TCouncilTax } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { formatNumberInGBP } from "../../../../../../utils/display";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const CouncilTaxStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {
    return (<span>Council Tax: {formatNumberInGBP(props.selected.councilTaxBucket)}/year</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['COUNCIL_TAX_KEY'] as TCouncilTax;

    if (!filter) {
      return null;
    }

    const value = props.selected.councilTaxBucket - filter;
    const text = formatNumberInGBP(value);

    return (<StatsArrow text={text} value={value} inverted={true} />);
  }

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}