export const uniqueArray = <T>(data: Array<T>) => [...new Set(data)];

export const uniqueArrayByKey = (data: Array<any>, key: any) => [...new Map(data.map(item => [item[key], item])).values()];

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export const findOne = <T>(haystack: Array<T>, arr: Array<T>): boolean => arr.some(v => haystack.includes(v));

/**
 * 
 * @param {array} array The array that we want to pick elements from
 * @returns {array} a number of randomly chosen elements from the source array
 */
export const pickFromArray = <T>(array: Array<T>, numElements: number): Array<T> => {
  // An empty array to store the picked elements
  const pickedElements = [];

  // Use a for loop to pick the desired number of elements
  for (let i = 0; i < numElements; i++) {
    // Generate a random index between 0 and the length of the array
    const randomIndex = Math.floor(Math.random() * array.length);

    // Use the random index to access an element from the array
    const randomElement = array[randomIndex];

    // Add the element to the array of picked elements
    pickedElements.push(randomElement);
  }

  return pickedElements;
}