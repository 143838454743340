export const Footer = () => {
  return (
    <footer className='footer p-6 mt-6'>
      <div className='content has-text-centered'>
        <p>
          <strong>BloomHouse</strong> is in Alpha and is beeing actively developed.
        </p>
        <p>
          <a href="https://www.flaticon.com/free-icons/home" title="home icons">Home icons created by Freepik - Flaticon</a>
        </p>
      </div>
    </footer>
  )
};