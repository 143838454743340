import { TBedroomNumber } from "../../../../../data/types/areas";
import { MaxBedroomsFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const MaxBedroomsFilterComponent = () => {
  const all = useSearchState(state => state.query.all.bedrooms);
  const selectedValue = useSearchState(state => state.filters.selectedValues['MAX_BEDROOMS_KEY'] as TBedroomNumber);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new MaxBedroomsFilter(e.target.value as TBedroomNumber));

  return (
    <span>
      <span className='mr-1'>Max Bedrooms:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>
    </span>
  );
};