import { LatLngTuple } from 'leaflet';
import create from 'zustand';
import { loadInfo, loadParkInfo, loadSchoolsInfo, loadTransportInfo } from '../../../data/services/load-quant-data.service';
import { TAreaName, TBedroomNumber, TBranchName, TCardinalPoint, TCouncilTax, TGreenCover, THousePrice, TInnerOrOuter, TInsideOrOutside, TLineName, TNorthOrSouth, TParkName, TPostCodePrefix, TPriceQuartile, TPropertyFeature, TPropertyScheme, TPropertyType, TSchoolDensity, TSchoolGrade, TSchoolName, TSortType, TStationName, TSubAreaCode, TSubAreaName, TTransactionType, TTransportQuality } from '../../../data/types/areas';
import { LocationBounds, LocationGPSCoords, TLocation } from '../../../data/types/location';
import { TColorItem, TLocationInfoValue, TParkInfoValue, TSchoolInfoValue, TTransportInfoValue } from '../../../data/types/quant';
import { generateUUID } from '../../../utils/math';
import { createFilterFromOption, sortData, getAvailableFilteringOptions, getAvailableSortingOptions, getFirstValidQueryItem, filterAllDataAndGetResultAndAvailableValues, subCodeToSubAreaGeoData, exploreToSubAreaGeoData, createResults2 } from './business-logic';
import { TGeoType } from '../../../data/types/geo';
import { AllExploreFilterOptions, AllFilteringOptions, AllSortOptions, CreateQuickGlanceItems, EMPTY_SEARCH_TERM, InitialGeoState, InitialQueryValues, InitialQueryValuesFromData, InitiateSearchableItemsFromQueryValues } from './defaults';
import { urlQueryToFilterDetails, urlQueryToSortOptions } from './url-utils';
import { IFilter } from './filters';
import { uniqueArray } from '../../../utils/array';

/// MAP

export type TMapState = {
  gpsCoords: LatLngTuple;
  bounds: any;
  key: string;
};

/// DATA

export type TDataState = {
  location: TLocation;
  loading: boolean;
  downloaded: boolean;
  quant: Array<TLocationInfoValue>;
  transport: TTransportInfoValue,
  parks: Array<TParkInfoValue>,
  schools: Array<TSchoolInfoValue>,
};

/// FILTERS

export type TFilterCategory =
  | 'Default'
  | 'Property'
  | 'Price'
  | 'Rent'
  | 'Geo'
  | 'Name'
  | 'Transport'
  | 'Environment'
  | 'Tax'
  | 'Schools'

export type TFilterKey =
  // no
  | 'FINAL_PASS_FILTER_KEY'
  | 'EMPTY_KEY'
  | 'TRANSACTION_TYPE_KEY'
  // house
  | 'PROPERTY_TYPE_KEY'
  | 'MIN_BEDROOMS_KEY'
  | 'MAX_BEDROOMS_KEY'
  | 'PROPERTY_FEATURE_KEY'
  | 'PROPERTY_SCHEME_KEY'
  | 'CHAIN_FREE_KEY'
  // price
  | 'MIN_HOUSE_PRICE_KEY'
  | 'MAX_HOUSE_PRICE_KEY'
  | 'AVG_HOUSE_PRICE_QUARTILE_KEY'
  | 'HOUSE_PRICE_AVG_YEARLY_INCREASE'
  | 'HOUSE_PRICE_DECADE_INCREASE'
  // rent
  | 'MIN_RENT_PRICE_KEY'
  | 'MAX_RENT_PRICE_KEY'
  | 'AVG_RENT_PRICE_QUARTILE_KEY'
  // geography
  | 'CARDINAL_POINT_KEY'
  | 'INNER_VS_OUTER_KEY'
  | 'NEXT_TO_RIVER_KEY'
  | 'NORTH_VS_SOUTH_KEY'
  // area
  | 'POST_CODE_KEY'
  | 'AREA_NAME_KEY'
  | 'SUB_AREA_NAME_KEY'
  // environment
  | 'INSIDE_CC_ZONE_KEY'
  | 'INSIDE_ULEZ_ZONE_KEY'
  | 'INSIDE_LEZ_ZONE_KEY'
  // transport
  | 'UNDERGROUND_LINE_KEY'
  | 'UNDERGROUND_STATION_KEY'
  | 'UNDERGROUND_BRANCH_KEY'
  | 'TRANSPORT_QUALITY_KEY'
  // tax
  | 'COUNCIL_TAX_KEY'
  // green
  | 'PARK_NAME_KEY'
  | 'GREEN_COVER_KEY'
  // schools
  | 'AVG_SCHOOL_GRADE_KEY'
  | 'NUMBER_OF_SCHOOLS_KEY'
  | 'SCHOOL_NAME_KEY';

/// QUERY VALUES

export type TQueryValues = {
  northOrSouth: Array<TNorthOrSouth>;
  cardinalPoints: Array<TCardinalPoint>;
  insideCC: Array<TInsideOrOutside>,
  insideLEZ: Array<TInsideOrOutside>,
  insideULEZ: Array<TInsideOrOutside>,
  innerOrOuter: Array<TInnerOrOuter>,
  nextToRiver: Array<boolean>,
  propertyType: Array<TPropertyType>,
  transactionType: Array<TTransactionType>,
  bedrooms: Array<TBedroomNumber>,
  priceQuartile: Array<TPriceQuartile>,
  rentQuartile: Array<TPriceQuartile>,
  minHousePrice: Array<THousePrice>,
  maxHousePrice: Array<THousePrice>,
  minRentPrice: Array<THousePrice>,
  maxRentPrice: Array<THousePrice>,
  areaNames: Array<TAreaName>,
  subAreaNames: Array<TSubAreaName>,
  postCodes: Array<TPostCodePrefix>,
  undergroundLines: Array<TLineName>,
  undergroundBranches: Array<TBranchName>,
  undergroundStations: Array<TStationName>,
  yearlyHousePriceIncrease: Array<THousePrice>,
  decadeHousePriceIncrease: Array<THousePrice>,
  propertyFeatures: Array<TPropertyFeature>,
  propertyScheme: Array<TPropertyScheme>,
  chainFree: Array<boolean>,
  transportQuality: Array<TTransportQuality>,
  councilTax: Array<TCouncilTax>,
  parks: Array<TParkName>,
  greenCover: Array<TGreenCover>,
  schoolGrade: Array<TSchoolGrade>,
  schoolDensity: Array<TSchoolDensity>,
  schools: Array<TSchoolName>,
};

export type TQueryState = {
  all: TQueryValues;
};

/// FILTERING

export type TFilterComponent = () => JSX.Element;

export type TFilterOption = {
  key: TFilterKey,
  component: TFilterComponent,
  category: TFilterCategory,
  explore: TExploreFilterKey | undefined,
  title: string,
  description: string,
  canDelete: boolean,
  isSelectable: boolean,
};

export type TFilterMap = { [key: string]: IFilter };
export type TSelectedValuesMap = { [key: string]: any };

export type TFilterState = {
  options: Array<TFilterOption>;
  remainingOptions: Array<TFilterOption>,
  filters: TFilterMap;
  selectedValues: TSelectedValuesMap;
  totalResults: Array<TSubAreaCode>,
  filtered: Array<TLocationInfoValue>,
};

/// EXPLORE FILTER 

export type TExploreFilterKey =
  | 'GREEN_COVER_XP_KEY'
  | 'COUNCIL_TAX_XP_KEY'
  | 'PARKS_XP_KEY'
  | 'AVG_PRICE_XP_KEY'
  | 'AVG_RENT_XP_KEY'
  | 'YoY_PRICE_XP_KEY'
  | '10Y_PRICE_XP_KEY'
  | 'PRiCE_QUARTILE_XP_KEY'
  | 'RENT_QUARTILE_XP_KEY'
  | 'TRANSPORT_QUALITY_XP_KEY'
  | 'TRANSPORT_LINES_XP_KEY'
  | 'IS_CC_XP_KEY'
  | 'IS_ULEZ_XP_KEY'
  | 'IS_LEZ_XP_KEY'
  | 'CARDINAL_POINT_XP_KEY'
  | 'AREA_XP_KEY'
  | 'DISTANCE_FROM_CETER_XP_KEY'
  | 'NEXT_TO_RIVER_XP_KEY'
  | 'NORTH_OR_SOUTH_XP_KEY'
  | 'SUB_AREA_XP_KEY'
  | 'POST_CODE_XP_KEY'
  | 'SCHOOL_GRADE_XP_KEY'
  | 'SCHOOL_NUMBER_XP_KEY'
  | 'DEFAULT_XP_KEY';

export type TExploreFilterOption = {
  key: TExploreFilterKey;
  title: string;
  description: string;
  colorFunc: TColorItem;
};

export type TAddFilterModalState = {
  selectedExplore: TExploreFilterOption | undefined,
  selectedFilter: TFilterOption | undefined,
  subAreaGeo: TGeoFeaturesState,
  mapUUid: string,
  categories: Array<TFilterCategory>,
  selectedCategory: TFilterCategory,
};

/// SORTING

export type TSortCategory =
  | 'Price'
  | 'Geo'
  | 'Environment'
  | 'Transport'
  | 'Tax'
  | 'Schools'

export type TSortKey =
  | 'PRICE_SORT_KEY'
  | 'YOY_PRICE_INCREASE_KEY'
  | 'DECADE_PRICE_INCREASE_KEY'
  | 'NORT_SOUTH_KEY'
  | 'WEST_EAST_KEY'
  | 'NEXT_TO_RIVER_KEY'
  | 'INNER_OR_OUTER_KEY'
  | 'IS_CC_KEY'
  | 'IS_LEZ_KEY'
  | 'IS_ULEZ_KEY'
  | 'CONNECTION_KEY'
  | 'COUNCIL_TAX_SORT_KEY'
  | 'GREEN_COVER_KEY'
  | 'SCHOOL_QUALITY_KEY'
  | 'SCHOOL_NUMBER_KEY';

export type TSortOption = {
  key: TSortKey;
  title: string;
  description: string;
  category: TSortCategory;
  value: TSortType;
  comparativeValue: (type: TTransactionType) => string;
};

export type TSortCondition = {
  comparators: Array<string>;
  orders: Array<'asc' | 'desc'>;
};

export type TSortState = {
  current: Array<TSortOption>;
  available: Array<TSortOption>;
};

/// RESULTS

export type TGeoFeaturesState = { uuid: string, type: TGeoType, features: Array<any> };

export type TGeoState = {
  subArea: TGeoFeaturesState;
};

export type TResultsState = {
  sorted: Array<TLocationInfoValue>,
  showing: number,
  total: number,
  geo: TGeoState;
};

/// MODAL state

export type TModalState = {
  filters: boolean;
  sorters: boolean;
}

/// SEARCHABLE state

export type TSearchCategoryName = 'Borough' | 'Ward' | 'Post Code' | 'Park' | 'School' | 'Tube Line' | 'Tube Branch' | 'Tube Station';
export type TSearchItem = { name: string, category: TSearchCategoryName, displayName: string };
export type TSearchCategory = { name: TSearchCategoryName, count: number };

export const TEXT_SEARCH_KEYS: Array<TFilterKey> = [
  'AREA_NAME_KEY', 'SUB_AREA_NAME_KEY', 'POST_CODE_KEY', 'SCHOOL_NAME_KEY', 'PARK_NAME_KEY',
  'UNDERGROUND_LINE_KEY', 'UNDERGROUND_BRANCH_KEY', 'UNDERGROUND_STATION_KEY',
];

export type TSearchableState = {
  items: Array<TSearchItem>;
  quickGlanceItems: Array<TSearchItem>;
  categories: Array<TSearchCategory>;
  searchQuery: string;
  selectedItem: TSearchItem | undefined;
};

/// RESULTS STATE 2

export type TResults2State = {
  geo: TGeoState;
  displayedResults: Array<TLocationInfoValue>;
  hasDirectResults: boolean;
  directResults: Array<TLocationInfoValue>;
  viableAlternatives: Array<TLocationInfoValue>,
  selectedResult: TLocationInfoValue | undefined;
  currentIndex: number;
  startIndex: number;
  endIndex: number;
  backButtonEnabled: boolean;
  nextButtonEnabled: boolean;
  minRank: number;
  maxRank: number;
}

/// MAIN APP STATE

export interface ISearchState {
  location: TLocation;
  map: TMapState,
  data: TDataState,
  filters: TFilterState,
  addFilterModal: TAddFilterModalState,
  sorting: TSortState,
  results: TResultsState,
  query: TQueryState,
  modal: TModalState,
  searchable: TSearchableState,
  results2: TResults2State;
  setLocation: (location: TLocation) => void;
  loadData: (location: TLocation, searchParams: URLSearchParams) => Promise<void>;
  // filters
  onAddFilter: (option: TFilterOption) => void,
  onChangeFilter: (filter: IFilter) => void,
  removeFilter: (filterKey: TFilterKey) => void,
  // sorting
  onAddSortOption: (option: TSortOption) => void;
  onRemoveSortOption: (option: TSortOption) => void;
  onChangeSortOption: (key: TSortKey, value: TSortType) => void;
  // display number of results shown
  increaseNumberOfResultsShowing: () => void;
  // modals
  openFiltersModal: () => void;
  closeFiltersModal: () => void;
  openSortersModal: () => void;
  closeSortersModal: () => void;
  // hover in & out
  hoverOverResult: (sub_code: TSubAreaCode) => void;
  hoverOutOfResult: () => void;
  // explore filters methods
  setExploreFilterOption: (key: TFilterOption) => void;
  selectCategory: (categ: TFilterCategory) => void;
  // search category
  setSearchQuery: (query: string) => void;
  setSelectedItem: (selectedItem: TSearchItem) => void;
  selectQuickSearchCategory: (categ: TSearchCategory) => void;
  // move to various results
  moveToNextResult: () => void;
  moveToPrevResult: () => void;
};

export const useSearchState = create<ISearchState>()((set) => ({
  location: 'london',
  map: {
    gpsCoords: [0, 0],
    bounds: [[0, 0], [0, 0]],
    key: '',
  },
  data: {
    location: 'london',
    loading: true,
    downloaded: false,
    quant: [],
    parks: [],
    schools: [],
    transport: {
      lines: [],
      stations: [],
    },
  },
  filters: {
    options: [],
    remainingOptions: AllFilteringOptions,
    filters: {},
    availableValues: {},
    selectedValues: {},
    totalResults: [],
    filtered: [],
  },
  addFilterModal: {
    selectedExplore: undefined,
    selectedFilter: undefined,
    subAreaGeo: InitialGeoState(),
    mapUUid: generateUUID(),
    categories: [],
    selectedCategory: 'Default',
  },
  sorting: {
    current: [],
    available: AllSortOptions,
  },
  results: {
    sorted: [],
    showing: 0,
    total: 0,
    geo: {
      subArea: InitialGeoState(),
    },
  },
  query: {
    all: InitialQueryValues(),
  },
  modal: {
    filters: false,
    sorters: false,
  },
  searchable: {
    items: [],
    quickGlanceItems: [],
    categories: [],
    searchQuery: EMPTY_SEARCH_TERM,
    selectedItem: undefined,
  },
  results2: {
    geo: {
      subArea: InitialGeoState(),
    },
    displayedResults: [],
    viableAlternatives: [],
    hasDirectResults: false,
    directResults: [],
    selectedResult: undefined,
    currentIndex: 0,
    startIndex: 0,
    endIndex: 0,
    backButtonEnabled: false,
    nextButtonEnabled: false,
    minRank: 0,
    maxRank: 0,
  },
  setLocation: (location: TLocation) => set((state) => ({
    ...state,
    location: location,
    map: {
      key: generateUUID(),
      gpsCoords: LocationGPSCoords.get(location)!,
      bounds: LocationBounds.get(location)!,
    },
  })),
  loadData: async (location: TLocation, searchParams: URLSearchParams) => {
    const quant = await loadInfo(location);
    const transport = await loadTransportInfo(location);
    const parks = await loadParkInfo(location);
    const schools = await loadSchoolsInfo(location);
    const data: TDataState = { location, quant, transport, parks, schools, loading: false, downloaded: true };

    const { optionsFromUrl, filtersFromUrl, selectedValuesFromUrl } = urlQueryToFilterDetails(searchParams);

    // the options
    const newOptions = optionsFromUrl;
    // the remaining ones
    const newRemainingOptions = getAvailableFilteringOptions(newOptions, AllFilteringOptions);

    // the filters themselves
    const newFilters = filtersFromUrl;

    // set selected values
    const newSelectedValues = selectedValuesFromUrl;

    // have to recaculate result again
    const { subCodes, filtered } = filterAllDataAndGetResultAndAvailableValues(data, newFilters);

    // and the final filters state
    const filters = { filtered, totalResults: subCodes, options: newOptions, filters: newFilters, remainingOptions: newRemainingOptions, selectedValues: newSelectedValues };

    const current = urlQueryToSortOptions(searchParams);
    const available = getAvailableSortingOptions(current, AllSortOptions);
    const sorting = { current, available };

    // results part
    const subArea = subCodeToSubAreaGeoData(filtered, subCodes);
    const geo = { subArea };
    const sorted = sortData(filtered, current, newSelectedValues['TRANSACTION_TYPE_KEY'] ?? 'buy');
    const total = sorted.length;
    const showing = 10 < total ? 10 : total;
    const results: TResultsState = { geo, sorted, showing, total };

    // results 2 part
    const results2 = createResults2(filtered, subCodes);

    // query part
    const newQuery = InitialQueryValuesFromData(data);
    const query = { all: newQuery };

    // searchable part
    const searchable = InitiateSearchableItemsFromQueryValues(newQuery, newSelectedValues);

    // explore filters part
    const mapUUid = generateUUID();
    const exploreSubAreaGeo = exploreToSubAreaGeoData(quant);
    const selectedExplore = AllExploreFilterOptions.find(e => e.key === 'DEFAULT_XP_KEY');
    const selectedFilter = undefined;
    const categories = uniqueArray(AllFilteringOptions.map(e => e.category).filter(e => e !== 'Default' && e !== 'Name').sort());
    const selectedCategory = categories[0];
    const exploreState: TAddFilterModalState = { selectedExplore, selectedFilter, subAreaGeo: exploreSubAreaGeo, mapUUid, categories, selectedCategory };

    console.log('ON LOAD', { data, filters, results2, query, sorting, exploreState });

    set(state => ({ ...state, data, filters, results, results2, sorting, query, addFilterModal: exploreState, searchable }));
  },
  onAddFilter: (option: TFilterOption) => set((state) => {

    // the options
    const newOptions = [...state.filters.options, option];
    // the remaining ones
    const newRemainingOptions = getAvailableFilteringOptions(newOptions, AllFilteringOptions);

    // the filters themselves
    const validQueryItem = getFirstValidQueryItem(state.query.all, option.key);
    const newFilter = createFilterFromOption(option, validQueryItem);
    const currentFilters = state.filters.filters;
    const newFilters = { ...currentFilters, [option.key]: newFilter! };

    // set selected values 
    const newSelectedValues = { ...state.filters.selectedValues, [option.key]: validQueryItem };

    // have to recaculate result again
    const { subCodes, filtered } = filterAllDataAndGetResultAndAvailableValues(state.data, newFilters);

    // and the final filters state
    const filters = { filtered, totalResults: subCodes, options: newOptions, filters: newFilters, remainingOptions: newRemainingOptions, selectedValues: newSelectedValues };

    // and now update the results
    const subArea = subCodeToSubAreaGeoData(filtered, subCodes);
    const geo = { subArea };
    const sorted = sortData(filtered, state.sorting.current, newSelectedValues['TRANSACTION_TYPE_KEY'] ?? 'buy');
    const total = sorted.length;
    const showing = 10 < total ? 10 : total;
    const results: TResultsState = { geo, sorted, showing, total };

    // results 2 part
    const results2 = createResults2(filtered, subCodes);

    const newState = { ...state, filters, results, results2 };

    console.log('ON ADD', newState);

    return newState;
  }),
  onChangeFilter: (filter: IFilter) => set((state) => {
    const filterKey = filter.key;

    // the options
    const newOptions = state.filters.options;
    // the remaining ones
    const newRemainingOptions = getAvailableFilteringOptions(newOptions, AllFilteringOptions);

    // modify the filters
    const currentFilters = state.filters.filters;
    const newFilter = filter;
    const newFilters = { ...currentFilters, [filterKey]: newFilter };

    // set selected values
    const newSelectedValues = { ...state.filters.selectedValues, [filterKey]: filter.value };

    // have to recaculate result again
    const { subCodes, filtered } = filterAllDataAndGetResultAndAvailableValues(state.data, newFilters);

    // and the final filters state
    const filters = { filtered, totalResults: subCodes, options: newOptions, filters: newFilters, remainingOptions: newRemainingOptions, selectedValues: newSelectedValues };

    // and now update the results
    const subArea = subCodeToSubAreaGeoData(filtered, subCodes);
    const geo = { subArea };
    const sorted = sortData(filtered, state.sorting.current, newSelectedValues['TRANSACTION_TYPE_KEY'] ?? 'buy');
    const total = sorted.length;
    const showing = 10 < total ? 10 : total;
    const results: TResultsState = { geo, sorted, showing, total };

    // results 2 part
    const results2 = createResults2(filtered, subCodes);

    const newState = { ...state, filters, results, results2 };

    console.log('ON CHANGE', newState);

    return newState;
  }),
  removeFilter: (filterKey: TFilterKey) => set((state) => {
    // const filterKey = option.key;

    // the options
    const newOptions = state.filters.options.filter(e => e.key !== filterKey);
    // the remaining ones
    const newRemainingOptions = getAvailableFilteringOptions(newOptions, AllFilteringOptions);

    // modify the filters
    const currentFilters = state.filters.filters;
    delete currentFilters[filterKey];
    const newFilters = currentFilters;

    // modify selected values
    const currentSelectedValues = state.filters.selectedValues;
    delete currentSelectedValues[filterKey];
    const newSelectedValues = currentSelectedValues;

    // have to recalculate the result again
    const { subCodes, filtered } = filterAllDataAndGetResultAndAvailableValues(state.data, newFilters);

    // and the final filters state
    const filters = { filtered, totalResults: subCodes, options: newOptions, filters: newFilters, remainingOptions: newRemainingOptions, selectedValues: newSelectedValues };

    // and now update the results
    const subArea = subCodeToSubAreaGeoData(filtered, subCodes);
    const geo = { subArea };
    const sorted = sortData(filtered, state.sorting.current, newSelectedValues['TRANSACTION_TYPE_KEY'] ?? 'buy');
    const total = sorted.length;
    const showing = 10 < total ? 10 : total;
    const results: TResultsState = { geo, sorted, showing, total };

    // results 2 part
    const results2 = createResults2(filtered, subCodes);

    const newState = { ...state, filters, results, results2 };

    console.log('ON DELETE', newState);

    return newState;
  }),
  onAddSortOption: (option: TSortOption) => set((state) => {
    const current = [...state.sorting.current, option];
    const available = getAvailableSortingOptions(current, AllSortOptions);
    const sorting = { current, available };
    const transactionType = state.filters.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

    const sorted = sortData(state.filters.filtered, current, transactionType);
    const total = sorted.length;
    const showing = state.results.showing;
    const results: TResultsState = { sorted, geo: state.results.geo, showing, total };

    return {
      ...state,
      sorting,
      results,
    };
  }),
  onRemoveSortOption: (option: TSortOption) => set((state) => {
    const current = state.sorting.current.filter(t => t.key !== option.key);
    const available = getAvailableSortingOptions(current, AllSortOptions);
    const sorting = { current, available };
    const transactionType = state.filters.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

    const sorted = sortData(state.filters.filtered, current, transactionType);
    const total = sorted.length;
    const showing = state.results.showing;
    const results: TResultsState = { sorted, geo: state.results.geo, showing, total };

    return {
      ...state,
      sorting,
      results,
    };
  }),
  onChangeSortOption: (key: TSortKey, value: TSortType) => set((state) => {
    const current = state.sorting.current.map((option: TSortOption) => {
      return option.key === key ? { ...option, value } : option;
    });
    const available = getAvailableSortingOptions(current, AllSortOptions);
    const sorting = { current, available };
    const transactionType = state.filters.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

    const sorted = sortData(state.filters.filtered, current, transactionType);
    const total = sorted.length;
    const showing = state.results.showing;
    const results: TResultsState = { sorted, geo: state.results.geo, showing, total };

    return {
      ...state,
      sorting,
      results,
    };
  }),
  increaseNumberOfResultsShowing: () => set((state) => {
    const currentNumberOfShownResults = state.results.showing;
    const totalNumberOfResults = state.results.sorted.length;
    const newNumberOfShownResults = (currentNumberOfShownResults + 10) < totalNumberOfResults ? (currentNumberOfShownResults + 10) : totalNumberOfResults;
    const results: TResultsState = { ...state.results, showing: newNumberOfShownResults }
    return {
      ...state,
      results
    };
  }),
  openFiltersModal: () => set((state) => ({
    ...state,
    addFilterModal: {
      ...state.addFilterModal,
      selectedFilter: undefined,
      selectedExplore: AllExploreFilterOptions.find(e => e.key === 'DEFAULT_XP_KEY'),
      selectedCategory: state.addFilterModal.categories[0],
    },
    modal: {
      ...state.modal,
      filters: true
    },
  })),
  closeFiltersModal: () => set((state) => ({ ...state, modal: { ...state.modal, filters: false } })),
  openSortersModal: () => set((state) => ({ ...state, modal: { ...state.modal, sorters: true } })),
  closeSortersModal: () => set((state) => ({ ...state, modal: { ...state.modal, sorters: false } })),
  hoverOverResult: (sub_code: TSubAreaCode) => set((state) => {
    const currentFeatures = state.results.geo.subArea.features;
    const newFeatures = currentFeatures.map(f => {
      return { ...f, hovering: f.properties.sub_code === sub_code };
    });
    const subArea: TGeoFeaturesState = { ...state.results.geo.subArea, uuid: generateUUID(), features: newFeatures };
    return {
      ...state,
      results: {
        ...state.results,
        geo: {
          ...state.results.geo,
          subArea,
        },
      },
    };
  }),
  hoverOutOfResult: () => set((state) => {
    const currentFeatures = state.results.geo.subArea.features;
    const newFeatures = currentFeatures.map(f => {
      return { ...f, hovering: false };
    });
    const subArea: TGeoFeaturesState = { ...state.results.geo.subArea, uuid: generateUUID(), features: newFeatures };
    return {
      ...state,
      results: {
        ...state.results,
        geo: {
          ...state.results.geo,
          subArea,
        },
      },
    };
  }),
  setExploreFilterOption: (option: TFilterOption) => set((state) => {
    const selected = AllExploreFilterOptions.find(e => e.key === option.explore);
    return {
      ...state,
      addFilterModal: {
        ...state.addFilterModal,
        selectedFilter: option,
        selectedExplore: selected,
        subAreaGeo: {
          ...state.addFilterModal.subAreaGeo,
          uuid: generateUUID(),
        },
      },
    };
  }),
  selectCategory: (categ: TFilterCategory) => set((state) => ({
    ...state,
    addFilterModal: {
      ...state.addFilterModal,
      selectedCategory: categ,
    },
  })),
  // quick search
  setSearchQuery: (query: string) => set((state) => ({
    ...state,
    searchable: {
      ...state.searchable,
      searchQuery: query,
      selectedItem: undefined,
      quickGlanceItems: [],
    },
  })),
  setSelectedItem: (selectedItem: TSearchItem) => set((state) => ({
    ...state,
    searchable: {
      ...state.searchable,
      selectedItem,
      quickGlanceItems: CreateQuickGlanceItems(selectedItem, state.data),
    },
  })),
  selectQuickSearchCategory: (categ: TSearchCategory) => set((state) => ({
    ...state,
    searchable: {
      ...state.searchable,
      searchQuery: `in ${categ.name}: `,
      selectedItem: undefined,
      quickGlanceItems: [],
    },
  })),
  moveToNextResult: () => set((state) => {
    const index = state.results2.currentIndex;
    const startIndex = state.results2.startIndex;
    const endIndex = state.results2.endIndex;
    const currentIndex = Math.min(index + 1, endIndex);
    const selectedResult = state.results2.displayedResults[currentIndex];
    const backButtonEnabled = currentIndex > startIndex;
    const nextButtonEnabled = currentIndex < endIndex;

    const currentFeatures = state.results2.geo.subArea.features;
    const newFeatures = currentFeatures.map(f => ({ ...f, hovering: f.properties.sub_code === selectedResult.sub_code }));
    const subArea: TGeoFeaturesState = { ...state.results2.geo.subArea, uuid: generateUUID(), features: newFeatures };
    const geo = { subArea };

    return {
      ...state,
      results2: {
        ...state.results2,
        geo,
        currentIndex,
        selectedResult,
        backButtonEnabled,
        nextButtonEnabled,
      },
    };
  }),
  moveToPrevResult: () => set((state) => {
    const index = state.results2.currentIndex;
    const startIndex = state.results2.startIndex;
    const endIndex = state.results2.endIndex;
    const currentIndex = Math.max(index - 1, startIndex);
    const selectedResult = state.results2.displayedResults[currentIndex];
    const backButtonEnabled = currentIndex > startIndex;
    const nextButtonEnabled = currentIndex < endIndex;

    const currentFeatures = state.results2.geo.subArea.features;
    const newFeatures = currentFeatures.map(f => ({ ...f, hovering: f.properties.sub_code === selectedResult.sub_code }));
    const subArea: TGeoFeaturesState = { ...state.results2.geo.subArea, uuid: generateUUID(), features: newFeatures };
    const geo = { subArea };

    return {
      ...state,
      results2: {
        ...state.results2,
        geo,
        currentIndex,
        selectedResult,
        backButtonEnabled,
        nextButtonEnabled,
      },
    };
  }),
}));