import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { normaliseWithoutSpacesToLowercase } from '../../../../../utils/string';
import { EMPTY_SEARCH_TERM } from '../../../state/defaults';
import { AreaNameFilter, ParkNameFilter, PostCodeFilter, SchoolNameFilter, SubAreaNameFilter, UndergroundBranchFilter, UndergroundLineFilter, UndergroundStationFilter } from '../../../state/filters';
import { TEXT_SEARCH_KEYS, TSearchCategory, TSearchItem, useSearchState } from '../../../state/search.state';

export type SearchMode = 'inplace' | 'redirect';

export const SearchBox = (props: { mode: SearchMode }) => {

  const navigate = useNavigate()

  const typedValue = useSearchState(state => state.searchable.searchQuery)
  const quickGlanceCategories = useSearchState(state => state.searchable.categories);
  const items = useSearchState(state => state.searchable.items);;
  const quickGlanceItems = useSearchState(state => state.searchable.quickGlanceItems);

  const setSearchQuery = useSearchState(state => state.setSearchQuery);
  const selectQuickSearchCategory = useSearchState(state => state.selectQuickSearchCategory);
  const setSelectedItem = useSearchState(state => state.setSelectedItem);

  const inputRef = useRef<HTMLInputElement>(null);

  const [itemsVisible, setItemsVisible] = useState(false);
  const [quickGlanceCategsVisible, setQuickGlanceCategsVisible] = useState(false);
  const [quickGlanceItemsVisible, setQuickGlanceItemsVisible] = useState(false);

  const onChangeFilter = useSearchState(state => state.onChangeFilter);
  const removeFilter = useSearchState(state => state.removeFilter);

  const onChange = (e: any) => {
    const value = e.target.value as string;
    setSearchQuery(value);

    if (value === EMPTY_SEARCH_TERM) {
      setQuickGlanceCategsVisible(true);
      removeFilters();
    }

    setQuickGlanceItemsVisible(false);
    if (value.length >= 2) {
      setItemsVisible(true);
      setQuickGlanceCategsVisible(false);
    } else {
      setItemsVisible(false);
      setQuickGlanceCategsVisible(true);
    }
  }

  const getPresentableData = () => {
    const searchFunction = (value: TSearchItem): boolean => {
      const name = normaliseWithoutSpacesToLowercase(value.name);
      const category = normaliseWithoutSpacesToLowercase(value.category);
      const potentialItem = normaliseWithoutSpacesToLowercase(`in ${category}: ${name}`);
      const query = normaliseWithoutSpacesToLowercase(typedValue);
      const queryWOCat = query.replace('in' + category + ':', '');
      return potentialItem.includes(query) || name.includes(queryWOCat);
    };

    return items.filter(searchFunction).slice(0, 20);
  }

  const onOptionsClick = (value: TSearchItem) => {
    removeFilters();
    setSearchQuery(value.name);
    setSelectedItem(value);
    setItemsVisible(false);

    if (props.mode === 'redirect') {
      navigate('/search/london');
    }

    switch (value.category) {
      case 'Borough': {
        onChangeFilter(new AreaNameFilter(value.name));
        break;
      }
      case 'Ward': {
        onChangeFilter(new SubAreaNameFilter(value.name));
        break;
      }
      case 'Post Code': {
        onChangeFilter(new PostCodeFilter(value.name));
        break;
      }
      case 'School': {
        onChangeFilter(new SchoolNameFilter(value.name));
        break;
      }
      case 'Park': {
        onChangeFilter(new ParkNameFilter(value.name));
        break;
      }
      case 'Tube Line': {
        onChangeFilter(new UndergroundLineFilter(value.name));
        break;
      }
      case 'Tube Branch': {
        onChangeFilter(new UndergroundBranchFilter(value.name));
        break;
      }
      case 'Tube Station': {
        onChangeFilter(new UndergroundStationFilter(value.name));
        break;
      }
    }
  }

  const removeFilters = () => TEXT_SEARCH_KEYS.forEach(key => removeFilter(key));

  const onBlur = () => {
    setQuickGlanceCategsVisible(false);
    setQuickGlanceItemsVisible(false);
  }

  const onFocus = () => {
    setQuickGlanceCategsVisible(!typedValue);
    setQuickGlanceItemsVisible(quickGlanceItems.length > 0);
  }

  const setQuickSearchFilter = (categ: TSearchCategory) => {
    selectQuickSearchCategory(categ);
    setQuickGlanceCategsVisible(false);
    setQuickGlanceItemsVisible(false);
    setItemsVisible(true);
    setTimeout(() => inputRef?.current?.focus(), 50);
  }

  return (
    <div>
      <div>
        <input
          ref={inputRef}
          type={'search'}
          className='input is-primary'
          placeholder='Search by postcodes, boroughs, wards, parks, schools or tube lines, branches and stations'
          aria-controls='dropdown-menu'
          onChange={onChange}
          value={typedValue}
          onFocus={onFocus}
          onBlur={onBlur} />
      </div>
      {itemsVisible && <div className={itemsVisible ? 'dropdown is-active' : 'dropdown'}>
        <div className='dropdown-menu' id='dropdown-menu' role='menu'>
          <div className='dropdown-content'>
            {
              getPresentableData().map((item) => (
                <a className='dropdown-item' key={item.name + '-' + item.category} onMouseDown={() => onOptionsClick(item)}>
                  {item.displayName}
                </a>
              ))
            }
          </div>
        </div>
      </div>}
      {quickGlanceCategsVisible && (
        <div className={quickGlanceCategsVisible ? 'dropdown is-active' : 'dropdown'}>
          <div className='dropdown-menu' id='quick-qlance-categories-menu' role='menu'>
            <div className='dropdown-content'>
              <div className='dropdown-item'>
                <strong>Quick search in</strong>
              </div>
              {quickGlanceCategories.map((category) => (
                <a className='dropdown-item' key={category.name} onMouseDown={() => setQuickSearchFilter(category)}>{category.name} <span>({category.count})</span></a>
              ))}
            </div>
          </div>
        </div>
      )}
      {quickGlanceItemsVisible && (
        <div className={quickGlanceItemsVisible ? 'dropdown is-active' : 'dropdown'}>
          <div className='dropdown-menu' id='quick-glance-items-menu' role={'menu'}>
            <div className='dropdown-content'>
              <div className='dropdown-item'>
                <strong>Related</strong>
              </div>
              {quickGlanceItems.map((item) => (
                <a className='dropdown-item' key={item.name + '-' + item.category} onMouseDown={() => onOptionsClick(item)}>
                  {item.displayName}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
};