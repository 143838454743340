import { TSchoolDensity } from '../../../../../data/types/areas';
import { SchoolDensityFilter } from '../../../state/filters';
import { useSearchState } from '../../../state/search.state';

export const SchoolDensityFilterComponent = () => {

  const all = useSearchState(state => state.query.all.schoolDensity);
  const selectedValue = useSearchState(state => state.filters.selectedValues['NUMBER_OF_SCHOOLS_KEY'] as TSchoolDensity);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new SchoolDensityFilter(e.target.value as TSchoolDensity));

  return (
    <span>
      <span className='mr-1'>Number of schools:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>
    </span>
  );
};