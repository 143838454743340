import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const NextToRiverStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {
    const copy = props.selected.nextToRiver ? 'Next to the river' : 'Further from the river';
    
    return (<span>{copy}</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['NEXT_TO_RIVER_KEY'] as boolean;

    if (!filter) {
      return null;
    }

    const value = filter === props.selected.nextToRiver ? 1 : -1;
    const text = value > 0 ? 'matching' : 'not matching';
    return (<StatsArrow text={text} value={value} />);
  };

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}