import rangeInclusive from 'range-inclusive';

export type TAvgPriceSteps = {
  min: number;
  max: number;
  range: Array<number>;
};

export const createStepsFromAvgPrices = (prices: Array<number>, step: number = 100_000): TAvgPriceSteps => {
  const min = Math.min(...prices);
  const max = Math.max(...prices);
  const minBound = Math.floor(min / step) * step;
  const maxBound = Math.ceil(max / step) * step;
  const range = rangeInclusive(minBound, maxBound, step);
  return { min: minBound, max: maxBound, range };
}

// taken from: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
export const generateUUID = (): string => {
  let
    d = new Date().getTime(),
    d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & (0x7 | 0x8))).toString(16);
  });
};


export const generateNumbers = (min: number, max: number, step: number): Array<number> => {
  const numbers = [];

  for (let i = min; i <= max; i += step) {
    numbers.push(i);
  }

  return numbers;
}