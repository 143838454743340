import { THousePrice, TTransactionType } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { formatNumberInGBP } from "../../../../../../utils/display";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const PriceStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const transasction = props.selectedValues['TRANSACTION_TYPE_KEY'] as TTransactionType;

  const Item = () => {
    const copy = transasction === 'buy' ? 'Price' : 'Rent';
    const extraCopy = transasction === 'buy' ? '' : '/month';
    const value = transasction === 'buy' ? props.selected.avgPrice : props.selected.avgRent;

    return (<span>Avg {copy} {formatNumberInGBP(value)}{extraCopy}</span>);
  }

  const Delta = () => {
    const minFilterKey = transasction === 'buy' ? 'MIN_HOUSE_PRICE_KEY' : 'MIN_RENT_PRICE_KEY';
    const maxFilterKey = transasction === 'buy' ? 'MAX_HOUSE_PRICE_KEY' : 'MAX_RENT_PRICE_KEY';

    const minFilter = props.selectedValues[minFilterKey] as THousePrice;
    const maxFilter = props.selectedValues[maxFilterKey] as THousePrice;

    const value = transasction === 'buy' ? props.selected.avgPrice : props.selected.avgRent;

    if (!minFilter && !maxFilter) {
      return null;
    }
    else if (minFilter && !maxFilter) {
      const delta = value - minFilter;
      const text = delta > 0 ? 'matching' : formatNumberInGBP(delta);
      return (<StatsArrow text={text} value={delta} />);
    }
    else if (!minFilter && maxFilter) {
      const delta = value - maxFilter;
      const text = delta > 0 ? formatNumberInGBP(delta) : 'matching';
      return (<StatsArrow text={text} value={delta} inverted={true} />);
    }
    else {
      if (minFilter <= value && value <= maxFilter) {
        return (<StatsArrow text='matching' value={1} />);
      }
      else if (value <= minFilter) {
        const delta = value - minFilter;
        const text = delta > 0 ? 'matching' : formatNumberInGBP(delta);
        return (<StatsArrow text={text} value={delta} />);
      }
      else {
        const delta = value - maxFilter;
        const text = delta > 0 ? formatNumberInGBP(delta) : 'matching';
        return (<StatsArrow text={text} value={delta} inverted={true} />);
      }
    }
  };

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}