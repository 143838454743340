import './../../index.css';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { SearchBox } from '../search/components/query/search/SearchBox';
import { useEffect } from 'react';
import { useSearchState } from '../search/state/search.state';
import { useSearchParams } from 'react-router-dom';

export const HomePage = () => {

  const example1Url = '/search/london?search=W3sia2V5IjoiVFJBTlNBQ1RJT05fVFlQRV9LRVkiLCJ2YWx1ZSI6ImJ1eSJ9LHsia2V5IjoiQVZHX1NDSE9PTF9HUkFERV9LRVkiLCJ2YWx1ZSI6IjMuNSJ9LHsia2V5IjoiR1JFRU5fQ09WRVJfS0VZIiwidmFsdWUiOjUwfSx7ImtleSI6IlBBUktfTkFNRV9LRVkiLCJ2YWx1ZSI6IkFsZXhhbmRyYSBQYXJrIn0seyJrZXkiOiJQUk9QRVJUWV9UWVBFX0tFWSIsInZhbHVlIjoiZmxhdCJ9LHsia2V5IjoiTUlOX0hPVVNFX1BSSUNFX0tFWSIsInZhbHVlIjoiNTAwMDAwIn0seyJrZXkiOiJNQVhfSE9VU0VfUFJJQ0VfS0VZIiwidmFsdWUiOiI3MDAwMDAifSx7ImtleSI6Ik1JTl9CRURST09NU19LRVkiLCJ2YWx1ZSI6IjIifSx7ImtleSI6Ik1BWF9CRURST09NU19LRVkiLCJ2YWx1ZSI6IjMifSx7ImtleSI6IkNIQUlOX0ZSRUVfS0VZIiwidmFsdWUiOnRydWV9XQ%3D%3D&sort=W10%3D';
  const example2Url = '/search/london?search=W3sia2V5IjoiVFJBTlNBQ1RJT05fVFlQRV9LRVkiLCJ2YWx1ZSI6InJlbnQifSx7ImtleSI6Ik5FWFRfVE9fUklWRVJfS0VZIiwidmFsdWUiOnRydWV9LHsia2V5IjoiTUlOX1JFTlRfUFJJQ0VfS0VZIiwidmFsdWUiOiIxMzAwIn0seyJrZXkiOiJNQVhfUkVOVF9QUklDRV9LRVkiLCJ2YWx1ZSI6IjE1MDAifSx7ImtleSI6IkNBUkRJTkFMX1BPSU5UX0tFWSIsInZhbHVlIjoid2VzdCJ9LHsia2V5IjoiR1JFRU5fQ09WRVJfS0VZIiwidmFsdWUiOjYwfSx7ImtleSI6IlRSQU5TUE9SVF9RVUFMSVRZX0tFWSIsInZhbHVlIjoiaGlnaCJ9LHsia2V5IjoiUFJPUEVSVFlfVFlQRV9LRVkiLCJ2YWx1ZSI6InRlcnJhY2VkIn0seyJrZXkiOiJNQVhfQkVEUk9PTVNfS0VZIiwidmFsdWUiOiIxIn1d&sort=W10%3D';
  const example3Url = '/search/london?search=W3sia2V5IjoiVFJBTlNBQ1RJT05fVFlQRV9LRVkiLCJ2YWx1ZSI6ImJ1eSJ9LHsia2V5IjoiVU5ERVJHUk9VTkRfQlJBTkNIX0tFWSIsInZhbHVlIjoiTm9ydGhlcm4gLSBFZGd3YXJlIGJyYW5jaCJ9LHsia2V5IjoiTUFYX0hPVVNFX1BSSUNFX0tFWSIsInZhbHVlIjoiMTAwMDAwMCJ9LHsia2V5IjoiTlVNQkVSX09GX1NDSE9PTFNfS0VZIiwidmFsdWUiOiI1In0seyJrZXkiOiJBVkdfU0NIT09MX0dSQURFX0tFWSIsInZhbHVlIjoiMy43NSJ9LHsia2V5IjoiTUFYX0JFRFJPT01TX0tFWSIsInZhbHVlIjoiMyJ9LHsia2V5IjoiUFJPUEVSVFlfRkVBVFVSRV9LRVkiLCJ2YWx1ZSI6WyJnYXJkZW4iXX0seyJrZXkiOiJIT1VTRV9QUklDRV9ERUNBREVfSU5DUkVBU0UiLCJ2YWx1ZSI6IjcwIn0seyJrZXkiOiJQUk9QRVJUWV9UWVBFX0tFWSIsInZhbHVlIjoidGVycmFjZWQifV0%3D&sort=W10%3D';

  const setLocation = useSearchState(state => state.setLocation);
  const loadData = useSearchState(state => state.loadData);
  
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLocation('london');
    loadData('london', searchParams);
  }, []);

  return (
    <div className='main-body'>
      <Navbar />
      <div className='layout m-4'>
        <div className='container mt-6'>
          <div className=''>
            <div className='title has-text-centered'>
              Supercharge your London property search.
            </div>
            <div className='subtitle has-text-centered'>
              BloomHouse helps you to find your dream house to rent or buy, using advanced filters.
            </div>
            <div className='columns'>
              <div className='column'>
                <SearchBox mode='redirect'/>
              </div>
              <div className='column is-narrow has-text-centered'>
                <a href='/search/london' className='button is-primary'>Explore</a>
              </div>
            </div>
          </div>
          <hr />
          <div className='columns'>
            <div className='column'>
              <div className='card'>
                <div className='card-image'>
                  <figure className='image is-4by3'>
                    <img src='/assets/images/home-page-example-1.jpg' alt='First example map result' />
                  </figure>
                </div>
                <div className='card-content'>
                  <div className='content'>
                    I'm exploring buying a 2 to 3 bedroom flat near Alexandra Palace, with a budget of between £500,000 and £700,000, that's also chain free. 
                    I'd also like to see nearby areas that have an average school rating between Good and Outstanding and are reasonably green.
                  </div>
                  <a className='button is-outline' href={example1Url}>Results</a>
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='card'>
                <div className='card-image'>
                  <figure className='image is-4by3'>
                    <img src='/assets/images/home-page-example-2.jpg' alt='Second example map result' />
                  </figure>
                </div>
                <div className='card-content'>
                  <div className='content'>
                    I want to to rent a 1 bedroom terraced house and my budget is roughly £1,300 to £1,500 per month. 
                    I don't have a specific area in mind, but I'd like it to be in the west of the city, close to the river, with good transport links and in very leafy areas.  
                  </div>
                  <a className='button is-outline' href={example2Url}>Results</a>
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='card'>
                <div className='card-image'>
                  <figure className='image is-4by3'>
                    <img src='/assets/images/home-page-example-3.jpg' alt='Third example map result' />
                  </figure>
                </div>
                <div className='card-content'>
                  <div className='content'>
                    I want to buy my dream terraced house with a budget of at most £1,000,000. It has to have 3 bedrooms and a garden. Location-wise, it must be on the Edgware branch of the Northern line.
                    I'm interested in areas with a good number of quality schools. Since this is a long term buy, I'm also interested in areas where property prices have gone up significantly in the last decade. 
                  </div>
                  <a className='button is-outline' href={example3Url}>Results</a>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className='has-text-weight-bold'>Locations</div>
            <div className='mt-2'>
              <span className='tag is-success'>London</span>
              <span className="tag is-light ml-2">More to come</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};