import { TInsideOrOutside } from "../../../../../data/types/areas";
import { InsideULEZFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const ULEZZoneFilterComponent = () => {

  const all = useSearchState(state => state.query.all.insideULEZ);
  const selectedValue = useSearchState(state => state.filters.selectedValues['INSIDE_ULEZ_ZONE_KEY'] as TInsideOrOutside);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new InsideULEZFilter(e.target.value as TInsideOrOutside));

  return (
    <span>
      <span className='mr-1'>Only locations</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>

      <span className='ml-1'>of the Ultra Low Emissions Zone</span>
    </span>
  );
};