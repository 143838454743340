import { TTransportQuality } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const TransportQualityStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {

    let copy = '';
    switch (props.selected.transportQuality) {
      case 'high': {
        copy = 'Well connected';
        break;
      }
      case 'mid': {
        copy = 'Moderately connected';
        break;
      }
      case 'low': {
        copy = 'Poorly connected';
        break;
      }
    }

    return (<span>{copy}</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['TRANSPORT_QUALITY_KEY'] as TTransportQuality;

    if (!filter) {
      return null;
    }

    const value = filter === props.selected.transportQuality ? 1 : -1;
    const text = value > 0 ? 'matching' : 'not matching';
    return (<StatsArrow text={text} value={value} />);
  }

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}