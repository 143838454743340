import './../index.css';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  return (
    <div className='m-2'>
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <Link to='/' className='navbar-item'>
            <img alt='Logo' src='/assets/icons/app-logo-main.png'/>
            <span className='logo ml-2'>BloomHouse</span>
          </Link>
          <a role='button' className='navbar-burger' aria-label='menu' aria-expanded='false' data-target='navbarBasicExample'>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>

        <div className='navbar-menu'>
          <div className='navbar-start'>
            <Link to='/search/london' className='navbar-item'>
              Search
            </Link>

            <Link to='data' className='navbar-item is-disabled'>
              Data
            </Link>
          </div>

          {/* <div className='navbar-end'>
            <div className='navbar-item'>
              <div className='buttons'>
                <a className='button is-primary'>
                  <strong>Sign up</strong>
                </a>
                <a className='button is-light'>
                  Log in
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </div>
  );
};