import { THousePrice } from "../../../../../data/types/areas";
import { MinPriceFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const MinPriceFilterComponent = () => {

  const all = useSearchState(state => state.query.all.minHousePrice);
  const selectedValue = useSearchState(state => state.filters.selectedValues['MIN_HOUSE_PRICE_KEY'] as THousePrice);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new MinPriceFilter(e.target.value as THousePrice));

  return (
    <span>
      <span className='mr-1'>Min Price:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>£{item}</option>))}
      </select>
    </span>
  );
};