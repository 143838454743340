import { TPropertyFeature } from "../../../../../data/types/areas";
import { PropertyFeatureFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state"

export const PropertyFeaturesFilterComponent = () => {

  const all = useSearchState(state => state.query.all.propertyFeatures);
  const selectedValue = useSearchState(state => state.filters.selectedValues['PROPERTY_FEATURE_KEY'] as Array<TPropertyFeature>);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => {
    const key = e.target.value as TPropertyFeature;
    const enabled = e.target.checked;
    const item = enabled ? [...selectedValue, key] : selectedValue.filter(e => e !== key);
    onChangeFilter(new PropertyFeatureFilter(item));
  }

  const isChecked = (e: TPropertyFeature) => selectedValue.includes(e);

  return (
    <span>
      <span className='mr-1'>Must haves</span>
      <br/>
      {
        all.map((e) => (
          <label className="checkbox mr-2" key={e}>
            <input checked={isChecked(e)} value={e} onChange={onChange} className="mr-1" type={'checkbox'}/>{e}
          </label>
        ))
      }
    </span>
  )
}