import { AllFilteringOptions } from '../../state/defaults';
import { TFilterCategory, TFilterOption, useSearchState } from '../../state/search.state';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { TSubAreaExploreGeoFeature } from '../../../../data/types/geo';
import chroma from 'chroma-js';

export const AddQueryModalComponent = () => {

  const allCategories = useSearchState(state => state.addFilterModal.categories);

  const mapState = useSearchState(state => state.map);
  const allFilters = useSearchState(state => AllFilteringOptions.filter(e => e.category !== 'Default' && e.isSelectable));
  const availableFilters = useSearchState(state => state.filters.remainingOptions);
  const addNewFilter = useSearchState(state => state.onAddFilter);
  const isOpen = useSearchState(state => state.modal.filters);
  const closeModal = useSearchState(state => state.closeFiltersModal);

  const subAreaGeo = useSearchState(state => state.addFilterModal.subAreaGeo);
  const mapUuid = useSearchState(state => state.addFilterModal.mapUUid);
  const selectedFilter = useSearchState(state => state.addFilterModal.selectedFilter);
  const selectedExplore = useSearchState(state => state.addFilterModal.selectedExplore);
  const selectOption = useSearchState(state => state.setExploreFilterOption);
  const selectedCateg = useSearchState(state => state.addFilterModal.selectedCategory);
  const setSelectedCateg = useSearchState(state => state.selectCategory);

  const onClose = () => closeModal();
  const onSubmit = () => {
    if (isOneSelected() && selectedFilter) {
      addNewFilter(selectedFilter);
      onClose();
    }
  }

  const selectCategory = (categ: TFilterCategory) => setSelectedCateg(categ);

  const isDisabled = (option: TFilterOption) => !availableFilters.map(e => e.key).includes(option.key);

  const isOneSelected = () => !!selectedFilter;

  const getFilteredData = () => allFilters.filter(f => f.category === selectedCateg);

  if (!isOpen) {
    return null;
  }

  const onHiglightSubArea = (e: any) => {
    const geoFeature = e.target.feature as TSubAreaExploreGeoFeature;
    const layer = e.target;
    const colorFunc = selectedExplore!.colorFunc;
    const color = geoFeature.data.colors[`${colorFunc}`];
    const darkenedColor = chroma(color).darken().hex();

    layer.setStyle({
      fillColor: darkenedColor,
    });
    layer.bringToFront();
  }

  const resetHighlight = (e: any) => {
    const geoFeature = e.target.feature as TSubAreaExploreGeoFeature;
    const colorFunc = selectedExplore!.colorFunc;
    const color = geoFeature.data.colors[`${colorFunc}`];

    e.target.setStyle({
      fillColor: color,
      fillOpacity: 1,
    });
  }

  const onEachFeature = (feature: any, layer: any) => {
    const geoFeature = feature as TSubAreaExploreGeoFeature;
    const name = geoFeature.properties.name;
    const colorFunc = selectedExplore!.colorFunc;
    const value = geoFeature.data.values[`${colorFunc}`];
    layer.bindTooltip(`<b>${name}</b>: ${value}`);

    layer.on({
      mouseover: onHiglightSubArea,
      mouseout: resetHighlight,
    });
  };

  const subAreaStyle = (feature: any) => {
    const geoFeature = feature as TSubAreaExploreGeoFeature;
    const colorFunc = selectedExplore?.colorFunc;
    const color = colorFunc ? geoFeature.data.colors[`${colorFunc}`] : '#0000';

    return {
      fillColor: color,
      fillOpacity: 1,
      weight: 0.35,
      opacity: 1,
      dashArray: '0',
      color: 'black', //: chroma(color).darken().hex()
    }
  };

  const onSelectOption = (option: TFilterOption) => {
    if (!isDisabled(option)) {
      selectOption(option);
    }
  }

  const isSelected = (option: TFilterOption) => option === selectedFilter;

  return (
    <div className={isOpen ? 'modal is-active' : 'modal'}>
      <div className='modal-background' onClick={onClose}></div>
      <div className='modal-card wide-modal-card'>
        <header className='modal-card-head'>
          <p className='control modal-card-title'>
            Add new filter
          </p>
          <button onClick={onClose} className='delete ml-2' aria-label='close'></button>
        </header>
        <section className='has-background-white p-4 is-scrollable modal-layout'>
          <div className='buttons has-addons is-centered'>
            {
              allCategories.map(categ => (
                <a key={categ} className={categ === selectedCateg ? 'is-active button' : 'button'} onClick={() => selectCategory(categ)}>
                  {categ}
                </a>
              ))
            }
          </div>
          <div className='columns is-scrollable' style={{ 'flex': '1 1 auto' }}>
            <div className='column is-scrollable'>
              <ul className='mt-5'>
                {getFilteredData().map((f, index) => (
                  <li key={f.key}
                    className='mt-2 mb-2 is-clickable'
                    onClick={() => onSelectOption(f)}
                    >
                    <a className={`${isDisabled(f) ? 'is-disabled' : ''} ${isSelected(f) ? 'has-text-primary' : ''}`}>{f.title}</a>
                    <br />
                    <small className={`${isDisabled(f) ? 'has-text-grey-light' : ''} ${isSelected(f) ? 'has-text-primary' : ''}`}>{f.description}</small>
                    {index < getFilteredData().length - 1 && <hr />}
                  </li>
                ))}
              </ul>
            </div>
            <div className='column display-desktop'>
              <MapContainer
                id='map-select'
                key={mapUuid}
                center={mapState.gpsCoords}
                bounds={mapState.bounds}
                scrollWheelZoom={false}>
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <GeoJSON
                  key={subAreaGeo.uuid}
                  data={subAreaGeo}
                  style={subAreaStyle}
                  onEachFeature={onEachFeature} />
              </MapContainer>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" disabled={!isOneSelected()} onClick={onSubmit}>Add</button>
          <button className="button" onClick={onClose}>Cancel</button>
        </footer>
      </div>
    </div>
  );
};