import { TAreaName, TBedroomNumber, TBranchName, TCardinalPoint, TCouncilTax, TGreenCover, THousePrice, TInnerOrOuter, TInsideOrOutside, TLineName, TNorthOrSouth, TParkName, TPostCodePrefix, TPriceQuartile, TPropertyFeature, TPropertyScheme, TPropertyType, TSchoolDensity, TSchoolGrade, TSchoolName, TStationName, TSubAreaName, TTransactionType, TTransportQuality } from '../../../data/types/areas';
import { TLocationInfoValue } from '../../../data/types/quant';
import { TDataState, TFilterKey } from './search.state';
import * as turf from 'turf';

export interface IFilter {
  get key(): TFilterKey;
  get value(): any;
  rank(data: TLocationInfoValue, all?: TDataState): TLocationInfoValue;
}

export class EmptyFilter implements IFilter {
  get key(): TFilterKey {
    return 'EMPTY_KEY';
  }
  get value(): any {
    return undefined;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class NorthVsSouthFilter implements IFilter {
  constructor(private item: TNorthOrSouth) { }

  get key(): TFilterKey {
    return 'NORTH_VS_SOUTH_KEY';
  }

  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return { ...data, rank: data.rank + (data.northOrSouth === this.item ? 1 : 0) };
  }
};

export class CardinalPointFilter implements IFilter {
  constructor(private item: TCardinalPoint) { }

  get key(): TFilterKey {
    return 'CARDINAL_POINT_KEY';
  }
  
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return { ...data, rank: data.rank + (data.cardinal === this.item ? 1 : 0) };
  }
};

export class TransactionTypeFilter implements IFilter {
  constructor(private item: TTransactionType) { }

  get key(): TFilterKey {
    return 'TRANSACTION_TYPE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class InsideCCFilter implements IFilter {
  constructor(private item: TInsideOrOutside) { }
  get key(): TFilterKey {
    return 'INSIDE_CC_ZONE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const comparison = this.item === 'inside';
    return { ...data, rank: data.rank + (data.isCC === comparison ? 1 : 0) };
  }
};

export class InsideLEZFilter implements IFilter {
  constructor(private item: TInsideOrOutside) { }
  get key(): TFilterKey {
    return 'INSIDE_LEZ_ZONE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const comparison = this.item === 'inside';
    return { ...data, rank: data.rank + (data.isLEZ === comparison ? 1 : 0) };
  }
};

export class InsideULEZFilter implements IFilter {
  constructor(private item: TInsideOrOutside) { }

  get key(): TFilterKey {
    return 'INSIDE_ULEZ_ZONE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const comparison = this.item === 'inside';
    return { ...data, rank: data.rank + (data.isULEZ === comparison ? 1 : 0) };
  }
};

export class InnerVsOuterFilter implements IFilter {
  constructor(private item: TInnerOrOuter) { }

  get key(): TFilterKey {
    return 'INNER_VS_OUTER_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    return { ...data, rank: data.rank + (data.innerOrOuter === this.item ? 1 : 0) };
  }
};

export class NextToRiverFilter implements IFilter {
  constructor(private item: boolean) { }

  get key(): TFilterKey {
    return 'NEXT_TO_RIVER_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    return { ...data, rank: data.rank + (data.nextToRiver === this.item ? 1 : 0) };
  }
};

export class PropertyTypeFilter implements IFilter {
  constructor(private item: TPropertyType) { }

  get key(): TFilterKey {
    return 'PROPERTY_TYPE_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class PriceQuartileFilter implements IFilter {
  constructor(private item: TPriceQuartile) { }

  get key(): TFilterKey {
    return 'AVG_HOUSE_PRICE_QUARTILE_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const quartileToNum = (q: TPriceQuartile) => q === 'lower' ? 0.3 : (q === 'middle' ? 0.6 : 1);
    const limit = quartileToNum(this.item);
    const diff = Math.max(((limit - quartileToNum(data.avgPriceQuartile)) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class RentQuartileFilter implements IFilter {
  constructor(private item: TPriceQuartile) { }

  get key(): TFilterKey {
    return 'AVG_RENT_PRICE_QUARTILE_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const quartileToNum = (q: TPriceQuartile) => q === 'lower' ? 0.3 : (q === 'middle' ? 0.6 : 1);
    const limit = quartileToNum(this.item);
    const diff = Math.max(((limit - quartileToNum(data.avgRentQuartile)) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class MinBedroomsFilter implements IFilter {
  constructor(private item: TBedroomNumber) { }

  get key(): TFilterKey {
    return 'MIN_BEDROOMS_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class MaxBedroomsFilter implements IFilter {
  constructor(private item: TBedroomNumber) { }

  get key(): TFilterKey {
    return 'MAX_BEDROOMS_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class MinPriceFilter implements IFilter {
  constructor(private item: THousePrice) { }

  get key(): TFilterKey {
    return 'MIN_HOUSE_PRICE_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.avgPrice) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class MaxPriceFilter implements IFilter {
  constructor(private item: THousePrice) { }

  get key(): TFilterKey {
    return 'MAX_HOUSE_PRICE_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((data.avgPrice - limit) / data.avgPrice) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class MinRentFilter implements IFilter {
  constructor(private item: THousePrice) { }
  get key(): TFilterKey {
    return 'MIN_RENT_PRICE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.avgRent) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class MaxRentFilter implements IFilter {
  constructor(private item: THousePrice) { }
  get key(): TFilterKey {
    return 'MAX_RENT_PRICE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((data.avgRent - limit) / data.avgRent) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class YearlyPriceIncreaseFilter implements IFilter {
  constructor(private item: THousePrice) { }
  get key(): TFilterKey {
    return 'HOUSE_PRICE_AVG_YEARLY_INCREASE';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.avgYearlyPriceIncreaseBucket) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class DecadePriceIncreaseFilter implements IFilter {
  constructor(private item: THousePrice) { }
  get key(): TFilterKey {
    return 'HOUSE_PRICE_DECADE_INCREASE';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.avgDecadePriceIncreaseBucket) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
}

export class PropertyFeatureFilter implements IFilter {
  constructor(private item: Array<TPropertyFeature>) { }
  get key(): TFilterKey {
    return 'PROPERTY_FEATURE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class PropertySchemeFilter implements IFilter {
  constructor(private item: TPropertyScheme) { }
  get key(): TFilterKey {
    return 'PROPERTY_SCHEME_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class ChainFreeFilter implements IFilter {
  constructor(private item: boolean) { }
  get key(): TFilterKey {
    return 'CHAIN_FREE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    return data;
  }
};

export class TransportQualityFilter implements IFilter {
  constructor(private item: TTransportQuality) { }

  get key(): TFilterKey {
    return "TRANSPORT_QUALITY_KEY";
  }

  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const qualityFunc = (q: TTransportQuality): number => q === 'low' ? 0 : (q === 'mid' ? 1 : 4);
    const limit = qualityFunc(this.item);
    const diff = Math.max(((limit - data.lines.length) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class CouncilTaxFilter implements IFilter {
  constructor(private item: TCouncilTax) { }

  get key(): TFilterKey {
    return 'COUNCIL_TAX_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((data.councilTaxBucket - limit) / data.councilTaxBucket) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class GreenCoverFilter implements IFilter {
  constructor(private item: TGreenCover) { }

  get key(): TFilterKey {
    return 'GREEN_COVER_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.greenCoverBucket) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class SchoolGradeFilter implements IFilter {
  constructor(private item: TSchoolGrade) { }

  get key(): TFilterKey {
    return 'AVG_SCHOOL_GRADE_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.schoolGrade) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

export class SchoolDensityFilter implements IFilter {
  constructor(private item: TSchoolDensity) { }

  get key(): TFilterKey {
    return 'NUMBER_OF_SCHOOLS_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    const limit = this.item;
    const diff = Math.max(((limit - data.schoolDensity) / limit) || 0, 0);
    const delta = 1 - diff;
    return { ...data, rank: data.rank + delta };
  }
};

//// WIDER AREA FILTERS

export class AreaNameFilter implements IFilter {
  private itemGSPCoords: Array<number> | undefined = undefined;

  constructor(private item: TAreaName) { }
  get key(): TFilterKey {
    return 'AREA_NAME_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue, all: TDataState): TLocationInfoValue {
    if (!this.item) {
      return data;
    }

    if (!this.itemGSPCoords) {
      const itemData = all.quant.find(e => e.name === this.item)!;
      this.itemGSPCoords = itemData.areaGpsCoords;
    }

    const dataGPSCoords = data.areaGpsCoords;
    const distance = turf.distance(turf.point(dataGPSCoords), turf.point(this.itemGSPCoords), 'kilometers');

    const limit = 35;
    const diff = Math.max(((limit - distance) / limit) || 0, 0);
    const delta = diff;
    const isDirectlySelected = this.item.toLowerCase() === data.name.toLowerCase();
    return { ...data, rank: data.rank + delta, isDirectlySelected };
  }
};

export class UndergroundLineFilter implements IFilter {
  constructor(private item: TLineName) { }
  get key(): TFilterKey {
    return 'UNDERGROUND_LINE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    if (!this.item) {
      return data;
    }
    const isDirectlySelected = data.lines.map(p => p.toLowerCase()).includes(this.item.toLowerCase());
    const delta = isDirectlySelected ? 1 : 0;
    return { ...data, rank: data.rank + delta, isDirectlySelected};
  }
};

export class UndergroundBranchFilter implements IFilter {
  constructor(private item: TBranchName) { }
  get key(): TFilterKey {
    return 'UNDERGROUND_BRANCH_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    if (!this.item) {
      return data;
    }
    const isDirectlySelected = data.branches.map(p => p.toLowerCase()).includes(this.item.toLowerCase());
    const delta = isDirectlySelected ? 1 : 0;
    return { ...data, rank: data.rank + delta, isDirectlySelected};
  }
}

//// DIRECT NAME FILTERS

export class SubAreaNameFilter implements IFilter {

  private itemGPSCoords: Array<number> | undefined = undefined;

  constructor(private item: TSubAreaName) { }
  get key(): TFilterKey {
    return 'SUB_AREA_NAME_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue, all: TDataState): TLocationInfoValue {
    if (!this.item) {
      return data;
    }

    if (!this.itemGPSCoords) {
      const itemData = all.quant.find(e => e.sub_name === this.item)!;
      this.itemGPSCoords = itemData.gpsCoords;
    }

    const dataGPSCoords = data.gpsCoords;
    const distance = turf.distance(turf.point(dataGPSCoords), turf.point(this.itemGPSCoords), 'kilometers');

    const limit = 35;
    const diff = Math.max(((limit - distance) / limit) || 0, 0);
    const delta = diff;
    const isDirectlySelected = this.item.toLowerCase() === data.sub_name.toLowerCase();
    return { ...data, rank: data.rank + delta, isDirectlySelected };
  }
};

export class PostCodeFilter implements IFilter {
  constructor(private item: TPostCodePrefix) { }
  get key(): TFilterKey {
    return 'POST_CODE_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    if (!this.item) {
      return data;
    }
    const isDirectlySelected = data.postCode.map(p => p.toLowerCase()).includes(this.item.toLowerCase());
    const delta = isDirectlySelected ? 1 : 0;
    return { ...data, rank: data.rank + delta, isDirectlySelected };
  }
};

export class SchoolNameFilter implements IFilter {
  constructor(private item: TSchoolName) { }

  get key(): TFilterKey {
    return 'SCHOOL_NAME_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue): TLocationInfoValue {
    if (!this.item) {
      return data;
    }
    const isDirectlySelected = data.schools.map(p => p.toLowerCase()).includes(this.item.toLowerCase());
    const delta = isDirectlySelected ? 1 : 0;
    return { ...data, rank: data.rank + delta, isDirectlySelected };
  }
};

export class UndergroundStationFilter implements IFilter {

  private itemGPSCoords: Array<number> | undefined = undefined;

  constructor(private item: TStationName) { }
  get key(): TFilterKey {
    return 'UNDERGROUND_STATION_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue, all: TDataState): TLocationInfoValue {
    if (!this.item) {
      return data;
    }

    if (!this.itemGPSCoords) {
      const itemName = this.item.toLowerCase();
      const stationData = all.transport.stations.find(e => itemName === e.name.toLowerCase())!;
      this.itemGPSCoords = stationData.gpsCoords; 
    }

    const dataGPSCoords = data.gpsCoords;
    const distance = turf.distance(turf.point(dataGPSCoords), turf.point(this.itemGPSCoords), 'kilometers');

    const limit = 35;
    const diff = Math.max(((limit - distance) / limit) || 0, 0);
    const isDirectlySelected = data.stations.map(p => p.toLowerCase()).includes(this.item.toLowerCase());
    const delta = isDirectlySelected ? 1 : diff;
    return { ...data, rank: data.rank + delta, isDirectlySelected };
  }
};

export class ParkNameFilter implements IFilter {

  private itemGPSCoords: Array<number> | undefined = undefined;

  constructor(private item: TParkName) { }

  get key(): TFilterKey {
    return 'PARK_NAME_KEY';
  }

  get value(): any {
    return this.item;
  }

  rank(data: TLocationInfoValue, all: TDataState): TLocationInfoValue {
    if (!this.item) {
      return data;
    }

    if (!this.itemGPSCoords) {
      const itemName = this.item.toLowerCase();
      const parkData = all.parks.find(e => itemName === e.name.toLowerCase())!;
      this.itemGPSCoords = parkData.parkGPS;
    }

    const dataGPSCoords = data.gpsCoords;
    const distance = turf.distance(turf.point(dataGPSCoords), turf.point(this.itemGPSCoords), 'kilometers');

    const limit = 35;
    const diff = Math.max(((limit - distance) / limit) || 0, 0);
    const delta = diff;
    const isDirectlySelected = data.parks.map(p => p.toLowerCase()).includes(this.item.toLowerCase());
    return { ...data, rank: data.rank + delta, isDirectlySelected };
  }
};

export class FinalPassFilter implements IFilter {
  constructor(private item: Array<number>) {}
  get key(): TFilterKey {
    return 'FINAL_PASS_FILTER_KEY';
  }
  get value(): any {
    return this.item;
  }
  rank(data: TLocationInfoValue): TLocationInfoValue {
    const centerOfDirectlySelectedArea = this.item;
    const dataGPSCoords = data.gpsCoords;
    const distance = turf.distance(turf.point(dataGPSCoords), turf.point(centerOfDirectlySelectedArea), 'kilometers');
    const limit = 35;
    const diff = Math.max(((limit - distance) / limit) || 0, 0);
    const delta = diff;
    return { ...data, rank: data.rank + delta };
  }
}