import { TSchoolDensity } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const SchoolDensityStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {
    return (<span>{props.selected.schoolDensity} schools</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['NUMBER_OF_SCHOOLS_KEY'] as TSchoolDensity;

    if (!filter) {
      return null;
    }

    const value = props.selected.schoolDensity - filter;
    const text = '' + value;

    return (<StatsArrow text={text} value={value}/>);
  }

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}