import { formResultLink } from "../../../../../data/services/link-result.service";
import { useSearchState } from "../../../state/search.state"

export const ResultExploreComponent = () => {

  const selected = useSearchState(state => state.results2.selectedResult);
  const selectedValues = useSearchState(state => state.filters.selectedValues);
  
  if (!selected) {
    return null;
  }

  return (
    <div className="content">
      <small>
        <h6>
          <span className='icons is-small mr-2'>
            <i className="fas fa-search"></i>
          </span>
          Explore properties
        </h6>
        <ul>
          {selected.postCode.map(postCode => (
            <li key={postCode}>
              <a rel='noreferrer' target={'_blank'} href={formResultLink(postCode, selectedValues)}>
                {postCode}
              </a>
            </li>)
          )}
        </ul>
      </small>
      <hr />
    </div>
  )
}