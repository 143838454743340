import { formNormalisedRanking } from '../../state/business-logic';
import { useSearchState } from '../../state/search.state'
import { ResultExploreComponent } from './subsections/ResultExploreComponent';
import { ResultInfoComponent } from './subsections/ResultInfoComponent';
import { ResultStatsComponent } from './subsections/ResultStatsComponent';

export const Results2Component = () => {

  const selected = useSearchState(state => state.results2.selectedResult);
  const hasDirectResults = useSearchState(state => state.results2.hasDirectResults);
  const currentIndex = useSearchState(state => state.results2.currentIndex);
  const endIndex = useSearchState(state => state.results2.endIndex);
  const backButtonDisabled = useSearchState(state => !state.results2.backButtonEnabled);
  const nextButtonDisabled = useSearchState(state => !state.results2.nextButtonEnabled);

  const moveToNextResult = useSearchState(state => state.moveToNextResult);
  const moveToPrevResult = useSearchState(state => state.moveToPrevResult);

  const minRank = useSearchState(state => state.results2.minRank);
  const maxRank = useSearchState(state => state.results2.maxRank);

  const formSectionSubtitle = (): string => {
    return hasDirectResults ?
      `Seeing ${currentIndex + 1} of ${endIndex + 1} direct results` :
      `Seeing ${currentIndex + 1} of ${endIndex + 1} related results`;
  }

  if (!selected) {
    return null;
  }

  const StarSection = () => {
    return (
      <>
        &nbsp;- {formNormalisedRanking(selected.rank, minRank, maxRank).toFixed(1)}
        <span className='icons is-small mr-2 has-text-warning'>
          <i className="fas fa-star"></i>
        </span>
      </>
    )
  }

  return (
    <div className='m-3'>
      <div className='columns is-vcentered is-mobile'>
        <div className='column is-narrow'>
          <button disabled={backButtonDisabled} className='button is-primary is-outlined is-small' onClick={() => moveToPrevResult()}>
            <span className='icon is-smal'>
              <i className='fas fa-chevron-left'></i>
            </span>
          </button>
        </div>
        <div className='column has-text-centered'>
          <div>
            <strong>
              {selected?.sub_name}<StarSection/>
            </strong>
          </div>
          <div className='is-size-7 has-text-grey'>
            {formSectionSubtitle()}
          </div>
        </div>
        <div className='column is-narrow'>
          <button disabled={nextButtonDisabled} className='button is-primary is-outlined is-small' onClick={() => moveToNextResult()}>
            <span className='icon is-smal'>
              <i className='fas fa-chevron-right'></i>
            </span>
          </button>
        </div>
      </div>
      <ResultExploreComponent />
      <ResultStatsComponent />
      <ResultInfoComponent />
    </div>
  )
}