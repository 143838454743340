import { THousePrice } from "../../../../../data/types/areas";
import { YearlyPriceIncreaseFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const YearlyPriceIncreaseFilterComponent = () => {
  
  const all = useSearchState(state => state.query.all.yearlyHousePriceIncrease);
  const selectedValue = useSearchState(state => state.filters.selectedValues['HOUSE_PRICE_AVG_YEARLY_INCREASE'] as THousePrice);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new YearlyPriceIncreaseFilter(e.target.value as THousePrice));

  return (
    <span>
      <span className='mr-1'>Minimum average yearly price increase:</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}%</option>))}
      </select>
    </span>
  );
};