import { TSortType } from '../../../data/types/areas';
import { createFilterFromOption } from './business-logic';
import { AllFilteringOptions, AllSortOptions } from './defaults';
import { IFilter, TransactionTypeFilter } from './filters';
import { TFilterKey, TFilterMap, TFilterOption, TSelectedValuesMap, TSortKey, TSortOption } from './search.state';

type TFilterAsQueryParam = {
  key: TFilterKey;
  value: any;
};

/**
 * 
 * @param filterMap a map where key is TFilterKey and the value is an instance of filter
 * @returns a base64 hash of the input, as a string 
 */
export const filterMapToUrlQuery = (filterMap: TFilterMap): { search: string } => {
  const result = Object.keys(filterMap).map((key: string) => {
    const filter = filterMap[key] as IFilter;
    const value = filter.value;
    return { key, value };
  });

  if (result.length === 0) {
    return { search: '' };
  }

  const string = JSON.stringify(result);
  const base64 = btoa(unescape(encodeURIComponent(string)));

  return { search: base64 };
};

/**
 * 
 * @param query a given URLSearchParams
 * @returns an object containing a list of tilers, a filter map and the starting values
 */
export const urlQueryToFilterDetails = (
  query: URLSearchParams
): {
  optionsFromUrl: Array<TFilterOption>,
  filtersFromUrl: TFilterMap,
  selectedValuesFromUrl: TSelectedValuesMap,
} => {

  const base64 = query.get('search');

  const itemsToSearch = [...AllFilteringOptions];
  const defaultOption = { ...itemsToSearch.find(e => e.key === 'TRANSACTION_TYPE_KEY')! };

  if (!base64) {
    return {
      optionsFromUrl: [defaultOption],
      filtersFromUrl: {
        'TRANSACTION_TYPE_KEY': new TransactionTypeFilter('buy'),
      },
      selectedValuesFromUrl: {
        'TRANSACTION_TYPE_KEY': 'buy',
      },
    };
  }

  const string = decodeURIComponent(escape(window.atob(base64)));
  const result = JSON.parse(string) as Array<TFilterAsQueryParam>;

  let optionsFromUrl = [];
  let filtersFromUrl: TFilterMap = {};
  let selectedValuesFromUrl: TSelectedValuesMap = {};

  for (const item of result) {
    const option = itemsToSearch.find(e => e.key === item.key)!;
    const selectedValue = item.value;
    const filter = createFilterFromOption(option, selectedValue);

    optionsFromUrl.push(option);
    filtersFromUrl[option.key] = filter;
    selectedValuesFromUrl[option.key] = selectedValue;
  }

  return { optionsFromUrl, filtersFromUrl, selectedValuesFromUrl };
};

/**
 * 
 * @param options an array of sorting options
 * @returns a base64 hash of the input, as a string 
 */
export const sortOptionsToUrlQuery = (options: Array<TSortOption>): { sort: string } => {
  const result = options.map((o: TSortOption) => {
    return { key: o.key, value: o.value };
  });

  const string = JSON.stringify(result);
  const base64 = btoa(unescape(encodeURIComponent(string)));

  return { sort: base64 };
};

/**
 * 
 * @param query a given URLSearchParams
 * @returns an array of sorting options and their associated values
 */
export const urlQueryToSortOptions = (query: URLSearchParams): Array<TSortOption> => {
  const base64 = query.get('sort');

  if (!base64) {
    return [];
  }

  const string = decodeURIComponent(escape(window.atob(base64)));
  const raw = JSON.parse(string) as Array<any>;

  return raw.map((obj: any) => {
    const key = obj['key'] as TSortKey;
    const value = obj['value'] as TSortType;
    const item = AllSortOptions.find(t => t.key === key);
    const realItem = { ...item, value } as TSortOption;
    return realItem;
  });
};