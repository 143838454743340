import './../../index.css';
import { useEffect } from 'react';
import { Navbar } from '../../components/Navbar';
import { TAreaLevelGeoFeature, TSubAreaLevelGeoFeature } from '../../data/types/geo';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { Footer } from '../../components/Footer';
import { useParams, useSearchParams } from 'react-router-dom';
import { TLocation } from '../../data/types/location';
import { LoadingQueryBuilderComponent } from './components/common/LoadingQueryBuilderComponent';
import { useSearchState } from './state/search.state';
import { filterMapToUrlQuery, sortOptionsToUrlQuery } from './state/url-utils';
import { QueryComponent } from './components/query/QueryComponent';
import { AddQueryModalComponent } from './components/add/AddQueryModalComponent';
import chroma from 'chroma-js';
import { Results2Component } from './components/results2/Results2Component';

export const SearchPage = () => {
  const { slug } = useParams();

  const dataDownloaded = useSearchState(state => state.data.downloaded);
  const mapState = useSearchState(state => state.map);
  const dataState = useSearchState(state => state.data);
  const geoState = useSearchState(state => state.results2.geo);
  const filterState = useSearchState(state => state.filters);
  const sortingState = useSearchState(state => state.sorting);

  const setLocation = useSearchState(state => state.setLocation);
  const loadData = useSearchState(state => state.loadData);

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!dataDownloaded) {
      setLocation(slug as TLocation);
      loadData(slug as TLocation, searchParams);
    }
  }, []);

  useEffect(() => {
    console.log('gabbox', 'current filters state', filterState);
    const filterParams = filterMapToUrlQuery(filterState.filters);
    const sortParams = sortOptionsToUrlQuery(sortingState.current);
    const params = { ...filterParams, ...sortParams };
    setSearchParams(params);

  }, [filterState, sortingState]);

  // map style

  const subAreaStyle = (feature: any) => {
    const geoFeature = feature as TSubAreaLevelGeoFeature;
    const fillColor = geoFeature.isDirectlySelected ? '#1a9641' : geoFeature.rankColor;
    const actualFillColor = geoFeature.hovering ? chroma(fillColor).darken().hex() : fillColor;
    return {
      fillColor: actualFillColor,
      fillOpacity: 1,
      weight: 0.75, // geoFeature.isDirectlySelected ? 1.25 : 0.75,
      opacity: geoFeature.selected ? 1 : 0,
      dashArray: '0',
      color: chroma(fillColor).darken().hex(),
    }
  };

  const onHiglightSubArea = (e: any) => {
    const feature = e.target.feature as TSubAreaLevelGeoFeature;
    const layer = e.target;

    if (feature.selected) {
      layer.setStyle({
        fillColor: feature.isDirectlySelected ? chroma('#1a9641').darken().hex() : chroma(feature.rankColor).darken().hex(),
        fillOpacity: 1,
      });

      layer.bringToFront();
    }
  }

  const resetHighlight = (e: any) => {
    const geoFeature = e.target.feature as TSubAreaLevelGeoFeature;
    const fillColor = geoFeature.isDirectlySelected ? '#1a9641' : geoFeature.rankColor;
    const actualFillColor = geoFeature.hovering ? chroma(fillColor).darken().hex() : fillColor;
    e.target.setStyle({
      fillColor: actualFillColor,
      fillOpacity: 1,
    });
  }

  const onEachFeature = (feature: any, layer: any) => {
    const geoFeature = feature as TAreaLevelGeoFeature;
    const name = geoFeature.properties.name;
    if (geoFeature.selected) {
      layer.bindTooltip(name);
    }

    layer.on({
      mouseover: onHiglightSubArea,
      mouseout: resetHighlight,
    });
  };

  return (
    <div className='main-body'>
      <Navbar />
      <div className='layout ml-4 mr-4 mt-4'>
        <div className='columns'>
          {<div className='column is-one-quarter'>
            <div className='sticky'>
              {dataState.loading && <LoadingQueryBuilderComponent />}
              {!dataState.loading && <QueryComponent />}
            </div>
          </div>}
          <div className='column map-column'>
            <div className='sticky'>
              <MapContainer
                id='map'
                key={mapState.key}
                center={mapState.gpsCoords}
                bounds={mapState.bounds}
                scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <GeoJSON key={geoState.subArea.uuid} onEachFeature={onEachFeature} data={geoState.subArea} style={subAreaStyle} />
              </MapContainer>
            </div>
          </div>
          <div className='column'>
            {!dataState.loading && (
              <>
              <Results2Component/>
              {/* <SearchResultsComponent /> */}
              </>
            )}
          </div>
        </div>
      </div>
      <AddQueryModalComponent />
      <Footer />
    </div>
  );
};
