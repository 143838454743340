import { TCardinalPoint } from "../../../../../data/types/areas"
import { CardinalPointFilter } from "../../../state/filters";
import { useSearchState } from "../../../state/search.state";

export const CardinalPointFilterComponent = () => {

  const all = useSearchState(state => state.query.all.cardinalPoints);
  const selectedValue = useSearchState(state => state.filters.selectedValues['CARDINAL_POINT_KEY'] as TCardinalPoint);
  const onChangeFilter = useSearchState(state => state.onChangeFilter);

  const onChange = (e: any) => onChangeFilter(new CardinalPointFilter(e.target.value as TCardinalPoint));

  return (
    <span>
      <span className='mr-1'>Only locations in the</span>

      <select className='query-select' value={selectedValue} onChange={onChange}>
        {all.map((item) => (<option value={item} key={item}>{item}</option>))}
      </select>

      <span className='ml-1'>of the city</span>
    </span>
  )
}