import { TTransactionType } from '../../../data/types/areas';
import { pickFromArray, uniqueArray } from '../../../utils/array';
import { generateUUID } from '../../../utils/math';
import { AreaNameFilterComponent } from '../components/query/filters/AreaNameFilterComponent';
import { CardinalPointFilterComponent } from '../components/query/filters/CardinalPointFilterComponent';
import { CCZoneFilterComponent } from '../components/query/filters/CCZoneFilterComponent';
import { ChainFreeFilterComponent } from '../components/query/filters/ChainFreeFilterComponent';
import { CouncilTaxFilterComponent } from '../components/query/filters/CouncilTaxFilterComponent';
import { DecadePriceIncreaseFilterComponent } from '../components/query/filters/DecadePriceIncreaseFilterComponent';
import { GreenCoverFilterComponent } from '../components/query/filters/GreenCoverFilterComponent';
import { InnerVsOuterFilterComponent } from '../components/query/filters/InnerVsOuterFilterComponent';
import { LEZZoneFilterComponent } from '../components/query/filters/LEZZoneFilterComponent';
import { MaxBedroomsFilterComponent } from '../components/query/filters/MaxBedroomsFilterComponent';
import { MaxPriceFilterComponent } from '../components/query/filters/MaxPriceFilterComponent';
import { MaxRentFilterComponent } from '../components/query/filters/MaxRentFilterComponent';
import { MinBedroomsFilterComponent } from '../components/query/filters/MinBedroomsFilterComponent';
import { MinPriceFilterComponent } from '../components/query/filters/MinPriceFilterComponent';
import { MinRentFilterComponent } from '../components/query/filters/MinRentFilterComponent';
import { NextToRiverFilterComponent } from '../components/query/filters/NextToRiverFilterComponent';
import { NorthVsSouthFilterComponent } from '../components/query/filters/NorthVsSouthFilterComponent';
import { ParkNameFilterComponent } from '../components/query/filters/ParkNameFilterComponent';
import { PostCodeFilterComponent } from '../components/query/filters/PostCodeFilterComponent';
import { PriceQuartileFilterComponent } from '../components/query/filters/PriceQuartileFilterComponent';
import { PropertyFeaturesFilterComponent } from '../components/query/filters/PropertyFeaturesFilterComponent';
import { PropertySchemeFilterComponent } from '../components/query/filters/PropertySchemeFilterComponent';
import { PropertyTypeFilterComponent } from '../components/query/filters/PropertyTypeFilterComponent';
import { RentQuartileFilterComponent } from '../components/query/filters/RentQuartileFilterComponent';
import { SchoolDensityFilterComponent } from '../components/query/filters/SchoolDensityFilterComponent';
import { SchoolGradeFilterComponent } from '../components/query/filters/SchoolGradeFilterComponent';
import { SchoolNameFilterComponent } from '../components/query/filters/SchoolNameFilterComponent';
import { SubAreaNameFilterComponent } from '../components/query/filters/SubAreaNameFilterComponent';
import { TransactionTypeFilterComponent } from '../components/query/filters/TransactionTypeFilterComponent';
import { TransportQualityFilterComponent } from '../components/query/filters/TransportQualityFilterComponent';
import { ULEZZoneFilterComponent } from '../components/query/filters/ULEZZoneFilterComponent';
import { UndergroundBranchFilterComponent } from '../components/query/filters/UndergroundBranchFilterComponent';
import { UndergroundLineFilterComponent } from '../components/query/filters/UndergroundLineFilterComponent';
import { UndergroundStationFilterComponent } from '../components/query/filters/UndergroundStationFilterComponent';
import { YearlyPriceIncreaseFilterComponent } from '../components/query/filters/YearlyPriceIncreaseFilterComponent';
import { TDataState, TQueryValues, TGeoFeaturesState, TSortOption, TFilterOption, TExploreFilterOption, TSearchableState, TSearchItem, TSearchCategory, TSelectedValuesMap, TEXT_SEARCH_KEYS } from './search.state';
import * as turf from 'turf';

export const AllSortOptions: Array<TSortOption> = [
  {
    key: 'PRICE_SORT_KEY',
    category: 'Price',
    title: 'Price',
    description: 'Sort by price in descing or ascending order',
    value: 'lowest',
    comparativeValue(type: TTransactionType) {
      return type === 'buy' ? 'sort.priceQuartile' : 'sort.rentQuartile';
    },
  },
  {
    key: 'NORT_SOUTH_KEY',
    category: 'Geo',
    title: 'North/South',
    description: 'Sort locations in the North or South first',
    value: 'lowest',
    comparativeValue(type: TTransactionType) {
      return 'sort.latitude';
    },
  },
  {
    key: 'WEST_EAST_KEY',
    category: 'Geo',
    title: 'West/East',
    description: 'Sort locations in the West or East first',
    value: 'lowest',
    comparativeValue(type: TTransactionType) {
      return 'sort.longitude';
    },
  },
  {
    key: 'CONNECTION_KEY',
    category: 'Transport',
    title: 'Connectivity',
    description: 'Sort by quality of transport connections',
    value: 'highest',
    comparativeValue(type: TTransactionType) {
      return 'sort.connection';
    },
  },
  {
    key: 'INNER_OR_OUTER_KEY',
    category: 'Geo',
    title: 'Inner/Outer',
    description: 'Sort by inner or outer London first',
    value: 'lowest',
    comparativeValue(type: TTransactionType) {
      return 'sort.innerOrOuter';
    },
  },
  {
    key: 'NEXT_TO_RIVER_KEY',
    category: 'Geo',
    title: 'Close to river',
    description: 'Sort by locations that are close to the river first or last',
    value: 'lowest',
    comparativeValue(type) {
      return 'sort.nextToRiver';
    },
  },
  {
    key: 'IS_CC_KEY',
    category: 'Environment',
    title: 'CC',
    description: 'Sort by presence in Congestion Charge (CC) Zone first or last',
    value: 'lowest',
    comparativeValue(type) {
      return 'sort.isCC';
    },
  },
  {
    key: 'IS_LEZ_KEY',
    category: 'Environment',
    title: 'LEZ',
    description: 'Sort by presence in Low Emission Zone (LEZ) first or last',
    value: 'lowest',
    comparativeValue(type) {
      return 'sort.isLEZ';
    },
  },
  {
    key: 'IS_ULEZ_KEY',
    category: 'Environment',
    title: 'ULEZ',
    description: 'Sort by presence in Ultra Low Emission Zone (ULEZ) first or last',
    value: 'lowest',
    comparativeValue(type) {
      return 'sort.isULEZ';
    },
  },
  {
    key: 'YOY_PRICE_INCREASE_KEY',
    category: 'Price',
    title: 'YoY Increase',
    description: 'Sort by Year on Year price increase in ascending or descending order',
    value: 'highest',
    comparativeValue(type) {
      return 'sort.yoyPriceIncrease';
    }
  },
  {
    key: 'DECADE_PRICE_INCREASE_KEY',
    category: 'Price',
    title: '10Y Increase',
    description: 'Sort by price increase in the last decade',
    value: 'highest',
    comparativeValue(type) {
      return 'sort.decadePriceIncrease';
    },
  },
  {
    key: 'COUNCIL_TAX_SORT_KEY',
    category: 'Tax',
    title: 'Council Tax',
    description: 'Sort by value of average council tax',
    value: 'lowest',
    comparativeValue(type) {
      return 'sort.councilTax';
    },
  },
  {
    key: 'GREEN_COVER_KEY',
    category: 'Environment',
    title: 'Green Cover',
    description: 'Sort by amount of tree & park coverage',
    value: 'highest',
    comparativeValue(type) {
      return 'sort.green';
    },
  },
  {
    key: 'SCHOOL_QUALITY_KEY',
    category: 'Schools',
    title: 'School quality',
    description: 'Sort by average school Ofted ranking',
    value: 'highest',
    comparativeValue(type) {
      return 'sort.schoolGrade';
    },
  },
  {
    key: 'SCHOOL_NUMBER_KEY',
    category: 'Schools',
    title: 'School density',
    description: 'Sort by number of schools in Ward',
    value: 'highest',
    comparativeValue(type) {
      return 'sort.schoolDensity';
    },
  }
];

export const AllFilteringOptions: Array<TFilterOption> = [
  {
    key: 'TRANSACTION_TYPE_KEY',
    category: 'Default',
    title: 'Transaction Type',
    description: 'Filter by specifying if you want to Buy or Rent',
    canDelete: false,
    component: TransactionTypeFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'CARDINAL_POINT_KEY',
    category: 'Geo',
    title: 'Cardinal Point',
    description: 'Filter by cardinal (north, south, east, etc) point in the city',
    canDelete: true,
    component: CardinalPointFilterComponent,
    explore: 'CARDINAL_POINT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'NORTH_VS_SOUTH_KEY',
    category: 'Geo',
    title: 'North/South',
    description: 'Filter by North or South side of the river',
    canDelete: true,
    component: NorthVsSouthFilterComponent,
    explore: 'NORTH_OR_SOUTH_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'INSIDE_CC_ZONE_KEY',
    category: 'Environment',
    title: 'CC',
    description: 'Filter by location inside or outside the Congestion Charge (CC) Zone',
    canDelete: true,
    component: CCZoneFilterComponent,
    explore: 'IS_CC_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'INSIDE_LEZ_ZONE_KEY',
    category: 'Environment',
    title: 'LEZ',
    description: 'Filter by location inside or outside the Low Emission Zone (LEZ)',
    canDelete: true,
    component: LEZZoneFilterComponent,
    explore: 'IS_LEZ_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'INSIDE_ULEZ_ZONE_KEY',
    category: 'Environment',
    title: 'ULEZ',
    description: 'Filter by location inside or outside the Ultra Low Emission Zone (ULEZ)',
    canDelete: true,
    component: ULEZZoneFilterComponent,
    explore: 'IS_ULEZ_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'INNER_VS_OUTER_KEY',
    category: 'Geo',
    title: 'Inner/Outer',
    description: 'Filter by Inner or Outer parts of the city',
    canDelete: true,
    component: InnerVsOuterFilterComponent,
    explore: 'DISTANCE_FROM_CETER_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'NEXT_TO_RIVER_KEY',
    category: 'Geo',
    title: 'Next to River',
    description: 'Filter by parts situation next to the river',
    canDelete: true,
    component: NextToRiverFilterComponent,
    explore: 'NEXT_TO_RIVER_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'PROPERTY_TYPE_KEY',
    category: 'Property',
    title: 'Property Type',
    description: 'Filter by property type (flat, terraced, etc)',
    canDelete: true,
    component: PropertyTypeFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'AVG_HOUSE_PRICE_QUARTILE_KEY',
    category: 'Price',
    title: 'Price Quartile',
    description: 'Filter by house price being in lower, middle or higher quartile',
    canDelete: true,
    component: PriceQuartileFilterComponent,
    explore: 'PRiCE_QUARTILE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'AVG_RENT_PRICE_QUARTILE_KEY',
    category: 'Rent',
    title: 'Rent Quartile',
    description: 'Filter by rent price being in lower, middle or higher quartile',
    canDelete: true,
    component: RentQuartileFilterComponent,
    explore: 'RENT_QUARTILE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'MIN_BEDROOMS_KEY',
    category: 'Property',
    title: 'Min Beds',
    description: 'Filter by minimum number of bedrooms',
    canDelete: true,
    component: MinBedroomsFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'MAX_BEDROOMS_KEY',
    category: 'Property',
    title: 'Max Beds',
    description: 'Filter by maximum number of bedrooms',
    canDelete: true,
    component: MaxBedroomsFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'MIN_HOUSE_PRICE_KEY',
    category: 'Price',
    title: 'Min Price',
    description: 'Filter by setting a minimum house price based on most recent average prices',
    canDelete: true,
    component: MinPriceFilterComponent,
    explore: 'AVG_PRICE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'MAX_HOUSE_PRICE_KEY',
    category: 'Price',
    title: 'Max Price',
    description: 'Filter by setting a maximum house price based on most recent average prices',
    canDelete: true,
    component: MaxPriceFilterComponent,
    explore: 'AVG_PRICE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'MIN_RENT_PRICE_KEY',
    category: 'Rent',
    title: 'Min Rent',
    description: 'Filter by setting a minimum monthly rent price based on most recent average rents',
    canDelete: true,
    component: MinRentFilterComponent,
    explore: 'AVG_RENT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'MAX_RENT_PRICE_KEY',
    category: 'Rent',
    title: 'Max Rent',
    description: 'Filter by setting a maximum monthly rent price based on most recent average rents',
    canDelete: true,
    component: MaxRentFilterComponent,
    explore: 'AVG_RENT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'AREA_NAME_KEY',
    category: 'Name',
    title: 'Borough',
    description: 'Filter by searching for and setting a Borough Name',
    canDelete: true,
    component: AreaNameFilterComponent,
    explore: 'AREA_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'SUB_AREA_NAME_KEY',
    category: 'Name',
    title: 'Ward',
    description: 'Filter by searching for and setting a Ward Name',
    canDelete: true,
    component: SubAreaNameFilterComponent,
    explore: 'SUB_AREA_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'POST_CODE_KEY',
    category: 'Name',
    title: 'Post Code',
    description: 'Filter by searching for and setting a Post Code',
    canDelete: true,
    component: PostCodeFilterComponent,
    explore: 'POST_CODE_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'UNDERGROUND_LINE_KEY',
    category: 'Transport',
    title: 'Tube Line',
    description: 'Filter for properties located alongside a certain Tube Line',
    canDelete: true,
    component: UndergroundLineFilterComponent,
    explore: 'TRANSPORT_LINES_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'UNDERGROUND_BRANCH_KEY',
    category: 'Transport',
    title: 'Tube Branch',
    description: 'Filter for properties located alongside a specific branch of a Tube Line',
    canDelete: true,
    component: UndergroundBranchFilterComponent,
    explore: 'TRANSPORT_LINES_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'UNDERGROUND_STATION_KEY',
    category: 'Transport',
    title: 'Tube Station',
    description: 'Filter by properties located alongside a specific Tube Station',
    canDelete: true,
    component: UndergroundStationFilterComponent,
    explore: 'TRANSPORT_LINES_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'HOUSE_PRICE_AVG_YEARLY_INCREASE',
    category: 'Price',
    title: 'YoY Price Increase',
    description: 'Filter only those properties that have increased by a certain percentage, on average, year on year, for the past 20 years',
    canDelete: true,
    component: YearlyPriceIncreaseFilterComponent,
    explore: 'YoY_PRICE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'HOUSE_PRICE_DECADE_INCREASE',
    category: 'Price',
    title: '10Y Price Increase',
    description: 'Filter only those properties that have increased by a certain percentage in the last 10 years',
    canDelete: true,
    component: DecadePriceIncreaseFilterComponent,
    explore: '10Y_PRICE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'PROPERTY_FEATURE_KEY',
    category: 'Property',
    title: 'Must Haves',
    description: 'Filter only those properties that include a number of must have items (balcony, garage, etc)',
    canDelete: true,
    component: PropertyFeaturesFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'PROPERTY_SCHEME_KEY',
    category: 'Property',
    title: 'Scheme',
    description: 'Filter only those properties that are sold under a scheme (new builds, retirement homes, etc)',
    canDelete: true,
    component: PropertySchemeFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'CHAIN_FREE_KEY',
    category: 'Property',
    title: 'Chain Free',
    description: 'Filter only those properties that are Chain Free',
    canDelete: true,
    component: ChainFreeFilterComponent,
    explore: 'DEFAULT_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'TRANSPORT_QUALITY_KEY',
    category: 'Transport',
    title: 'Quality',
    description: 'Filter by quality of transport links',
    canDelete: true,
    component: TransportQualityFilterComponent,
    explore: 'TRANSPORT_QUALITY_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'COUNCIL_TAX_KEY',
    category: 'Tax',
    title: 'Council Tax',
    description: 'Filter by average value of Council Tax per year',
    canDelete: true,
    component: CouncilTaxFilterComponent,
    explore: 'COUNCIL_TAX_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'PARK_NAME_KEY',
    category: 'Environment',
    title: 'Parks',
    description: 'Filter by proximity to a park',
    canDelete: true,
    component: ParkNameFilterComponent,
    explore: 'PARKS_XP_KEY',
    isSelectable: false,
  },
  {
    key: 'GREEN_COVER_KEY',
    category: 'Environment',
    title: 'Green Cover',
    description: 'Filter by minimum amount of green cover (parks, trees, gardens, etc) out of ward land area',
    canDelete: true,
    component: GreenCoverFilterComponent,
    explore: 'GREEN_COVER_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'AVG_SCHOOL_GRADE_KEY',
    category: 'Schools',
    title: 'School Ratings',
    description: 'Filter by average Ofsted school ratings for each Ward, where 1 = Inadequate, 4 = Outstanding',
    canDelete: true,
    component: SchoolGradeFilterComponent,
    explore: 'SCHOOL_GRADE_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'NUMBER_OF_SCHOOLS_KEY',
    category: 'Schools',
    title: 'Number of schools',
    description: 'Filter by the number of schools in each Ward',
    canDelete: true,
    component: SchoolDensityFilterComponent,
    explore: 'SCHOOL_NUMBER_XP_KEY',
    isSelectable: true,
  },
  {
    key: 'SCHOOL_NAME_KEY',
    category: 'Schools',
    title: 'School name',
    description: 'Filter by school name',
    canDelete: true,
    component: SchoolNameFilterComponent,
    explore: 'SCHOOL_GRADE_XP_KEY',
    isSelectable: false,
  },
];

export const AllExploreFilterOptions: Array<TExploreFilterOption> = [
  {
    key: 'GREEN_COVER_XP_KEY',
    title: 'Green cover',
    description: 'Presents the green cover (parks, trees, gardens) on a Ward level, color coded',
    colorFunc: 'greenCover',
  },
  {
    key: 'COUNCIL_TAX_XP_KEY',
    title: 'Council tax',
    description: 'Presents the average council tax per Borough, color coded',
    colorFunc: 'councilTax',
  },
  {
    key: 'PARKS_XP_KEY',
    title: 'Parks',
    description: 'Presents wards that are close to a park or not',
    colorFunc: 'parks',
  },
  {
    key: 'AVG_PRICE_XP_KEY',
    title: 'Average House Price',
    description: 'Presents the average house price for each ward',
    colorFunc: 'avgPrice',
  },
  {
    key: 'AVG_RENT_XP_KEY',
    title: 'Average Rent',
    description: 'Presents the average monthly rent prices for each ward',
    colorFunc: 'avgRent',
  },
  {
    key: 'YoY_PRICE_XP_KEY',
    title: 'YoY Price Increase',
    description: 'Presents the average Year on Year price increase for the last 20 years',
    colorFunc: 'avgYoyIncrease',
  },
  {
    key: '10Y_PRICE_XP_KEY',
    title: '10 Year Price Increase',
    description: 'Presents the average price increase in a 10 year period',
    colorFunc: 'avg10YIncrease',
  },
  {
    key: 'PRiCE_QUARTILE_XP_KEY',
    title: 'Price Quartile',
    description: 'Presents the low, medium and high house price quartiles',
    colorFunc: 'priceQuartile',
  },
  {
    key: 'RENT_QUARTILE_XP_KEY',
    title: 'Rent Quartile',
    description: 'Presents the low, medium and high monthly rent price quartile',
    colorFunc: 'rentQuartile',
  },
  {
    key: 'TRANSPORT_QUALITY_XP_KEY',
    title: 'Transport Quality',
    description: 'Presents areas of low, medium and high transport connectivity',
    colorFunc: 'transportQuality',
  },
  {
    key: 'TRANSPORT_LINES_XP_KEY',
    title: 'Tube Lines',
    description: 'Presents areas that are close to zero, one or more tube lines',
    colorFunc: 'lines',
  },
  {
    key: 'IS_CC_XP_KEY',
    title: 'CC Zone',
    description: 'Presents all the wards in the Congenstion Charge (CC) Zone',
    colorFunc: 'isCC',
  },
  {
    key: 'IS_ULEZ_XP_KEY',
    title: 'ULEZ',
    description: 'Presents all the wards in the Ultra Low Emission Zone (ULEZ)',
    colorFunc: 'isULEZ',
  },
  {
    key: 'IS_LEZ_XP_KEY',
    title: 'LEZ',
    description: 'Presents all the wards in the Low Emission Zone (LEZ)',
    colorFunc: 'isLEZ',
  },
  {
    key: 'CARDINAL_POINT_XP_KEY',
    title: 'Cardinal Point',
    description: 'Presents all london cardinal points: central, west, east, noth, south',
    colorFunc: 'cardinal',
  },
  {
    key: 'AREA_XP_KEY',
    title: 'Borough',
    description: 'Presents all london boroughs in a color coded fashion',
    colorFunc: 'area',
  },
  {
    key: 'DISTANCE_FROM_CETER_XP_KEY',
    title: 'Inner or Outer',
    description: 'Presents the inner and outer boroughs of London',
    colorFunc: 'innerOrOuter',
  },
  {
    key: 'NEXT_TO_RIVER_XP_KEY',
    title: 'Next to river',
    description: 'Presents the wards next to the river',
    colorFunc: 'nextToRiver',
  },
  {
    key: 'NORTH_OR_SOUTH_XP_KEY',
    title: 'North vs South',
    description: 'Color coded North vs South of the river',
    colorFunc: 'northOrSouth',
  },
  {
    key: 'SUB_AREA_XP_KEY',
    title: '',
    description: '',
    colorFunc: 'sub_area',
  },
  {
    key: 'POST_CODE_XP_KEY',
    title: '',
    description: '',
    colorFunc: 'postCode',
  },
  {
    key: 'DEFAULT_XP_KEY',
    title: '',
    description: '',
    colorFunc: 'other',
  },
  {
    key: 'SCHOOL_GRADE_XP_KEY',
    title: '',
    description: '',
    colorFunc: 'schoolGrade',
  },
  {
    key: 'SCHOOL_NUMBER_XP_KEY',
    title: '',
    description: '',
    colorFunc: 'schoolDensity',
  },
];

export const InitialGeoState = (): TGeoFeaturesState => ({
  uuid: generateUUID(),
  type: 'FeatureCollection',
  features: [],
});

export const InitialQueryValues = (): TQueryValues => ({
  transactionType: ['buy', 'rent'],
  northOrSouth: ['north', 'south'],
  cardinalPoints: ['center', 'east', 'north', 'south', 'west'],
  insideCC: ['inside', 'outside'],
  insideLEZ: ['inside', 'outside'],
  insideULEZ: ['inside', 'outside'],
  innerOrOuter: ['inner', 'outer'],
  nextToRiver: [true, false],
  propertyType: ['flat', 'semi-detached', 'detached', 'terraced', 'bungalow'],
  priceQuartile: ['lower', 'middle', 'upper'],
  rentQuartile: ['lower', 'middle', 'upper'],
  bedrooms: ['Studio', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'],
  minHousePrice: [],
  maxHousePrice: [],
  minRentPrice: [],
  maxRentPrice: [],
  areaNames: [],
  subAreaNames: [],
  postCodes: [],
  undergroundLines: [],
  undergroundBranches: [],
  undergroundStations: [],
  yearlyHousePriceIncrease: [],
  decadeHousePriceIncrease: [],
  propertyFeatures: ['balcony', 'garage', 'garden'],
  propertyScheme: ['new-build', 'auction', 'retirement', 'shared-ownership', 'existing'],
  chainFree: [true, false],
  transportQuality: ['high', 'mid', 'low'],
  councilTax: [],
  parks: [],
  greenCover: [],
  schoolGrade: [],
  schoolDensity: [],
  schools: [],
});

export const InitialQueryValuesFromData = (data: TDataState): TQueryValues => {

  const minHousePrice = uniqueArray(data.quant.map(e => e.minPriceBucket)).sort((a, b) => a - b);
  const maxHousePrice = uniqueArray(data.quant.map(e => e.maxPriceBucket)).sort((a, b) => a - b);
  const minRentPrice = uniqueArray(data.quant.map(e => e.minRentBucket)).sort((a, b) => a - b);
  const maxRentPrice = uniqueArray(data.quant.map(e => e.maxRentBucket)).sort((a, b) => a - b);
  const areaNames = uniqueArray(data.quant.map(e => e.name)).sort();
  const subAreaNames = uniqueArray(data.quant.map(e => e.sub_name)).sort();
  const postCodes = uniqueArray(data.quant.flatMap(e => e.postCode));
  const undergroundLines = uniqueArray(data.quant.flatMap(e => e.lines));
  const undergroundBranches = uniqueArray(data.quant.flatMap(e => e.branches));
  const undergroundStations = uniqueArray(data.quant.flatMap(e => e.stations));
  const yearlyHousePriceIncrease = uniqueArray(data.quant.map(e => e.avgYearlyPriceIncreaseBucket)).sort((a, b) => a - b);
  const decadeHousePriceIncrease = uniqueArray(data.quant.map(e => e.avgDecadePriceIncreaseBucket)).sort((a, b) => a - b);
  const councilTax = uniqueArray(data.quant.map(e => e.councilTaxBucket)).sort((a, b) => a - b);
  const parks = uniqueArray(data.quant.flatMap(e => e.parks)).sort();
  const greenCover = uniqueArray(data.quant.map(e => e.greenCoverBucket)).sort((a, b) => a - b);
  const schoolGrade = uniqueArray(data.quant.map(e => e.schoolGrade)).sort((a, b) => a - b);
  const schoolDensity = uniqueArray(data.quant.map(e => e.schoolDensity)).sort((a, b) => a - b);
  const schools = uniqueArray(data.quant.flatMap(e => e.schools)).sort();

  return {
    ...InitialQueryValues(),
    minHousePrice,
    maxHousePrice,
    minRentPrice,
    maxRentPrice,
    areaNames,
    subAreaNames,
    postCodes,
    undergroundLines,
    undergroundBranches,
    undergroundStations,
    yearlyHousePriceIncrease,
    decadeHousePriceIncrease,
    councilTax,
    parks,
    greenCover,
    schoolGrade,
    schoolDensity,
    schools,
  };
};

export const InitiateSearchableItemsFromQueryValues = (values: TQueryValues, selectedValues: TSelectedValuesMap): TSearchableState => {
  let items: Array<TSearchItem> = [];

  values.areaNames.forEach((name) => items.push({ name, category: 'Borough', displayName: `${name} (Borough)` }));
  values.subAreaNames.forEach((name) => items.push({ name, category: 'Ward', displayName: `${name} (Ward)` }));
  values.postCodes.forEach((name) => items.push({ name, category: 'Post Code', displayName: name }));
  values.parks.forEach((name) => items.push({ name, category: 'Park', displayName: `${name} (Park)` }));
  values.schools.forEach((name) => items.push({ name, category: 'School', displayName: `${name} (School)` }));
  values.undergroundLines.forEach((name) => items.push({ name, category: 'Tube Line', displayName: `${name} (Tube Line)` }));
  values.undergroundBranches.forEach((name) => items.push({ name, category: 'Tube Branch', displayName: `${name} (Tube Branch)` }));
  values.undergroundStations.forEach((name) => items.push({ name, category: 'Tube Station', displayName: `${name} (Station)` }));

  const categories: Array<TSearchCategory> = [
    {
      name: 'Borough',
      count: values.areaNames.length
    },
    {
      name: 'Ward',
      count: values.subAreaNames.length,
    },
    {
      name: 'Post Code',
      count: values.postCodes.length,
    },
    {
      name: 'School',
      count: values.schools.length,
    },
    {
      name: 'Park',
      count: values.parks.length,
    },
    {
      name: 'Tube Line',
      count: values.undergroundLines.length,
    },
    {
      name: 'Tube Branch',
      count: values.undergroundBranches.length,
    },
    {
      name: 'Tube Station',
      count: values.undergroundStations.length,
    },
  ];

  let searchQuery = EMPTY_SEARCH_TERM;
  for (const key of TEXT_SEARCH_KEYS) {
    if (selectedValues[key]) {
      searchQuery = selectedValues[key];
      break;
    }
  }

  const selectedItem = undefined;
  const quickGlanceItems: Array<TSearchItem> = [];

  return { items, categories, searchQuery, selectedItem, quickGlanceItems };
};

export const CreateQuickGlanceItems = (selected: TSearchItem, data: TDataState): Array<TSearchItem> => {
  switch (selected.category) {
    case 'Borough':
      return data.quant
        .filter(e => e.name === selected.name)
        .map(e => ({ name: e.sub_name, category: 'Ward', displayName: `${e.sub_name} (Ward)` }));
    case 'Tube Line':
      return data.transport.lines
        .filter(e => e.line === selected.name)
        .flatMap(e => e.branches)
        .map(e => ({ name: e, category: 'Tube Branch', displayName: `${e} (Tube Branch)` }))
    case 'Tube Branch':
      return data.transport.stations
        .filter(e => e.branches.includes(selected.name))
        .map(e => ({ name: e.name, category: 'Tube Station', displayName: `${e.name} (Station)` }));
    case 'Post Code':
      return pickFromArray(data.quant, 15)
        .flatMap(e => e.postCode)
        .slice(0, 15)
        .map(e => ({ name: e, category: 'Post Code', displayName: e }));
    case 'Tube Station': {
      const current = data.transport.stations.find(e => e.name.toLowerCase() === selected.name.toLowerCase());

      if (!current) {
        return [];
      }

      return data.transport.stations
        .sort((a, b) => {
          const distFromA = turf.distance(turf.point(a.gpsCoords), turf.point(current.gpsCoords), 'kilometers');
          const distFromB = turf.distance(turf.point(b.gpsCoords), turf.point(current.gpsCoords), 'kilometers');
          return distFromA - distFromB;
        })
        .slice(0, 15)
        .map(e => ({ name: e.name, category: 'Tube Station', displayName: `${e.name} (Station)` }));
    }
    case 'Ward': {
      const current = data.quant.find(e => e.sub_name.toLowerCase() === selected.name.toLowerCase());

      if (!current) {
        return [];
      }

      return data.quant
        .sort((a, b) => {
          const distFromA = turf.distance(turf.point(a.gpsCoords), turf.point(current.gpsCoords), 'kilometers');
          const distFromB = turf.distance(turf.point(b.gpsCoords), turf.point(current.gpsCoords), 'kilometers');
          return distFromA - distFromB;
        })
        .slice(0, 15)
        .map(e => ({ name: e.sub_name, category: 'Ward', displayName: `${e.sub_name} (Ward)` }));
    }
    case 'Park': { 
      const current = data.parks.find(e => e.name.toLowerCase() === selected.name.toLowerCase());

      if (!current) {
        return [];
      }

      return data.parks
        .sort((a, b) => {
          const distFromA = turf.distance(turf.point(a.parkGPS), turf.point(current.parkGPS), 'kilometers');
          const distFromB = turf.distance(turf.point(b.parkGPS), turf.point(current.parkGPS), 'kilometers');
          return distFromA - distFromB;
        })
        .slice(0, 15)
        .map(e => ({ name: e.name, category: 'Park', displayName: `${e.name} (Park)` }));
    }
    case 'School': {
      const current = data.schools.find(e => e.name.toLowerCase() === selected.name.toLowerCase());

      if (!current) {
        return [];
      }

      return data.schools
        .sort((a, b) => {
          const distFromA = turf.distance(turf.point(a.gpsCoords), turf.point(current.gpsCoords), 'kilometers');
          const distFromB = turf.distance(turf.point(b.gpsCoords), turf.point(current.gpsCoords), 'kilometers');
          return distFromA - distFromB;
        })
        .slice(0, 15)
        .map(e => ({ name: e.name, category: 'School', displayName: `${e.name} (School)` }));
    }
  }
}

export const EMPTY_SEARCH_TERM = '';