import { TInsideOrOutside } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const LEZZoneStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {
    const condition = props.selected.isLEZ;
    const copy = condition ? 'Inside' : 'Outside';
    
    return (<span>{copy} the Low Emission Zone (LEZ)</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['INSIDE_LEZ_ZONE_KEY'] as TInsideOrOutside;

    if (!filter) {
      return null;
    }

    const comp = filter === 'inside';
    const value = comp === props.selected.isLEZ ? 1 : -1;
    const text = value > 0 ? 'matching' : 'not matching';
    return (<StatsArrow text={text} value={value} />);
  };

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}