import { useSearchState } from '../../../state/search.state';
import { CardinalPointsStats } from './stats/CardinalPointStats';
import { CCZoneStats } from './stats/CCZoneStats';
import { CouncilTaxStats } from './stats/CouncilTaxStats';
import { DecadeIncreaseStats } from './stats/DecadeIncreaseStats';
import { ExpensiveStats } from './stats/ExpensiveStats';
import { GreenCoverStats } from './stats/GreenCoverStats';
import { InnerVsOuterStats } from './stats/InnerVsOuterStats';
import { LEZZoneStats } from './stats/LEZZoneStats';
import { NextToRiverStats } from './stats/NextToRiverStats';
import { NorthSouthStats } from './stats/NorthSouthStats';
import { PriceStats } from './stats/PriceStats';
import { QuartileStats } from './stats/QuartileStats';
import { SchoolDensityStats } from './stats/SchoolDensityStats';
import { SchoolRatingStats } from './stats/SchoolRatingStats';
import { TransportQualityStats } from './stats/TransportQualityStats';
import { ULEZZoneStats } from './stats/ULEZZoneStats';
import { YoYIncreaseStats } from './stats/YoYIncreaseStats';

export const ResultStatsComponent = () => {

  const selected = useSearchState(state => state.results2.selectedResult);
  const selectedValues = useSearchState(state => state.filters.selectedValues);

  if (!selected) {
    return null;
  }

  return (
    <div className='content'>
      <h6>
        <small>
          <span className='icons is-small mr-2 has-text-success'>
            <i className='fas fa-leaf'></i>
          </span>
          Environment
        </small>
      </h6>
      <ul>
        <CCZoneStats selected={selected} selectedValues={selectedValues} />
        <LEZZoneStats selected={selected} selectedValues={selectedValues} />
        <ULEZZoneStats selected={selected} selectedValues={selectedValues} />
        <GreenCoverStats selected={selected} selectedValues={selectedValues} />
      </ul>

      <h6 className='mt-3'>
        <small>
          <span className={'icon is-small mr-2 has-text-info'}>
            <i className="fas fa-compass"></i>
          </span>
          Geo
        </small>
      </h6>
      <ul>
        <CardinalPointsStats selected={selected} selectedValues={selectedValues} />
        <NorthSouthStats selected={selected} selectedValues={selectedValues} />
        <InnerVsOuterStats selected={selected} selectedValues={selectedValues} />
        <NextToRiverStats selected={selected} selectedValues={selectedValues} />
      </ul>

      <h6 className='mt-3'>
        <small>
          <span className='icons is-small mr-2 has-text-success'>
            <i className="fas fa-money-bill"></i>
          </span>
          Price
        </small>
      </h6>
      <ul>
        <QuartileStats selected={selected} selectedValues={selectedValues} />
        <PriceStats selected={selected} selectedValues={selectedValues} />
        <ExpensiveStats selected={selected} selectedValues={selectedValues} />
        <YoYIncreaseStats selected={selected} selectedValues={selectedValues} />
        <DecadeIncreaseStats selected={selected} selectedValues={selectedValues} />
      </ul>

      <h6 className='mt-3'>
        <small>
          <span className='icons is-small mr-2'>
            <i className="fas fa-graduation-cap"></i>
          </span>
          Education
        </small>
      </h6>
      <ul>
        <SchoolRatingStats selected={selected} selectedValues={selectedValues} />
        <SchoolDensityStats selected={selected} selectedValues={selectedValues} />
      </ul>

      <h6 className='mt-3'>
        <small>
          <span className='icons is-small mr-2 has-text-success'>
            <i className="fas fa-money-bill"></i>
          </span>
          Taxes
        </small>
      </h6>
      <ul>
        <CouncilTaxStats selected={selected} selectedValues={selectedValues} />
      </ul>

      <h6 className='mt-3'>
        <small>
          <span className='icons is-small mr-2 has-text-info'>
            <i className="fas fa-subway"></i>
          </span>
          Transport
        </small>
      </h6>
      <ul>
        <TransportQualityStats selected={selected} selectedValues={selectedValues} />
      </ul>
      <hr />
    </div>
  )
};