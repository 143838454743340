import { TCardinalPoint } from "../../../../../../data/types/areas";
import { TLocationInfoValue } from "../../../../../../data/types/quant";
import { capitalize } from "../../../../../../utils/string";
import { TSelectedValuesMap } from "../../../../state/search.state";
import { StatsArrow } from "../common/StatsArrow";
import { StatsStructure } from "../common/StatsStructure";

export const CardinalPointsStats = (props: { selected: TLocationInfoValue, selectedValues: TSelectedValuesMap }) => {
  const Item = () => {
    return (<span>{capitalize(props.selected.cardinal)} London</span>);
  }

  const Delta = () => {
    const filter = props.selectedValues['CARDINAL_POINT_KEY'] as TCardinalPoint;

    if (!filter) {
      return null;
    }

    const value = filter === props.selected.cardinal ? 1 : -1;
    const text = value > 0 ? 'matching' : 'not matching';
    return (<StatsArrow text={text} value={value} />);
  };

  return <StatsStructure Item={<Item />} Delta={<Delta />} />;
}